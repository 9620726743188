@import url('../../utils/color-palatte.css');

.Settings__Container {
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
  margin: auto;
  padding: 10px;
  /* padding: 10px 0; */
  /* background-color: var(--FEDERAL_DASHBOARD_BG); */
}
.Settings__Title__Container { 
  width: 100%;
}
.Settings__Title {
  text-align: center;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 10px;
}
.Settings__Button__Container {
  border: none;
  display: flex;
  justify-content: space-between;
  background: transparent;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
  width: 100%;
}
.Settings__Button__Text {
  color: var(--GRAY_MAIN);
  text-align: left;
  margin: 12px;
  margin-left: 20px;
  margin-right: 10px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.2rem;
}
.Settings__Select__Button__Image {
  width: 20px;
}
.Settings__Select__Button {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.Settings__Select__Button {
  border: none;
  background: transparent;
  text-decoration: none;
}
.Settings__Button__Text__Container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Setings__Flag-container {
  width: 70px;
  margin-right: -5px;
}