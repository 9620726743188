@import url('../../utils/color-palatte.css');

.OptionMenu__Overlay {
  background: #0006;
}

.OptionMenu__Container {
  background: #fff;
  box-sizing: border-box;
  padding: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 380px;
  border-radius: 10px;
}

.OptionModal__Title {
  width: 100%;
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  margin: 5px;
}

.OptionModal__List {
  width: 100%;
  list-style: none;
  box-sizing: border-box;
  padding: 0 10px;
  margin: 5px 0;
}

.OptionModal__Options {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background: transparent;
  margin: -1px 0;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .1s;
  cursor: pointer;
}

.OptionModal__Options:hover {
  background: #0003;
}

.OptionModal__Options:last-child {
  border-bottom: none;
  border-radius: 0 0 5px 5px;
}
.OptionModal__Options:last-child:hover {
  background: #F003;
}

.OptionModal__Options-p {
  font-size: 1.1rem;
  margin: 0;
  margin-left: 32px;
}

.OptionModal__Options-img {
  width: 21px;
  margin-left: 10px;
}