/* MAIN */
:root {
  --FEDERAL_BLUE: #2573b4;
  --FEDERAL_BLUE_STRONG: #04458F;
  --FEDERAL_LOW_BLUE: #dde5ed;
  --FEDERAL_LIGHT_BLUE: #d6eafa;
  --BLUE_LINK: #15c;
  --FEDERAL_GRAYED_BLUE: #1D3A5B;
  --DARK_OFFICIAL_BLUE: #011327;
  --FEDERAL_BLUE_SOFT: #4AA7E5;
  
  /* WHITE */
  
  --FEDERAL_WHITE: #e7eae1;
  --FEDERAL_WHITE2: #f1f0eb;
  --SERVICES_BACKGROUND_WHITE: #f2f2f2;
  --USER_FINANCE_BACKGROUND_WHITE: #dddddf;
  --WG_STATS_BACKGROUND_WHITE: rgb(255, 255, 255);
  --VOTING_BACKGROUND_WHITE: #ffffff;
  --WHITE_DARK: #e6e6e6;
  
  /* GRAY */
  --FEDERAL_GRAY: #61819a;
  --GRAY_WHITE: #ddd;
  --FEDERAL_DASHBOARD_BG: #CCCCCC;
  
  /* EXTRAS */
  --WHITE_MAIN: #FFFFFF;
  --WHITE_SECOND: #FFFDFD;
  --GRAY_MAIN: #6D6868;
  --GRAY_SECOND: #272323;
  --GRAY_SECOND_TRANSPARENCY: rgba(39, 35, 35, 0.8);
  --GRAY_SECOND_TRANSPARENCY_FULL: rgba(39, 35, 35, 0.2);
  --FEDERAL_RED: #ae2728;
  --BLACK: black;
  --SPACE: #000;
  --GOLD: #eab143;
  --WARNING_YELLOW: #f8e84d;
  --FEDERAL_GREEN: #5fdba7;
  --FEDERAL_BLACK: #2d2926;
  --FEDERAL_BLACK_LIGHT: #343434;
  --FEDERAL_BLUE_TRANSPARENCY: #2573b466;
  --WARNING_RED_TRANSPARENCY: #EB211285;

  /* DARK MODE */
  --UNSATURATED_BLACK_BLUE: #1D3A5B;
  --DARK_OFFICIAL_BLUE: #011327;
  --BRIGHT_BLUE: #4AA7E5;
  --BRIGHT_BLUE_HOVER: rgba(74, 167, 229, 0.6);
  --LIGHT_HOVER: rgba(200,200,200,0.3);
  --DARK_HOVER: rgba(30,30,30,0.3);
  --WHITE_HOVER: rgba(255,255,255,0.4);
  --BLACK_HOVER: rgba(30,30,30,0.4);
}