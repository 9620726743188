@import url('../../../utils/color-palatte.css');

.IdModal__Overlay {
  background-color: #0005;
  box-sizing: border-box;
  padding: 10px;
}

.IdModal__Container {
  background-color: white;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  border-radius: 10px;
  width: 100%;
  max-width: 780px;
}

.IdModal__Header {
  position: relative;
  border-bottom: 1px solid var(--GRAY_SECOND_TRANSPARENCY_FULL);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.IdModal__Header h1 {
  margin-top: 10px;
  color: var(--FEDERAL_BLUE)
}

.IdModal__Header img {
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  cursor: pointer;
}

.IdModal__SubTitle {
  font-family: 'Source Sans Pro', sans-serif;
  color: var(--GRAY_SECOND);
  margin-bottom: 20px;
}

.IdModal__Continue, .IdModal__Share {
  width: calc(100% - 60px);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Source Sans Pro', sans-serif;
  margin: 8px 0;
  font-size: 1.1rem;
  border: none;
  border-radius: 8px;
}

.IdModal__Continue {
  background-color: var(--FEDERAL_BLUE);
  color: #FFF;
  margin-top: 30px;
  transition: all .2s;
  cursor: pointer;
}

.IdModal__Continue:hover {
  background-color: var(--FEDERAL_BLUE_SOFT);
}

.IdModal__Share {
  background-color: var(--WARNING_YELLOW);
  color: var(--FEDERAL_BLUE);
  transition: all .2s;
  cursor: pointer;
}

.IdModal__Share:hover {
  background-color: var(--FEDERAL_LOW_BLUE);
}

@media (max-width: 440px) {
  
  .IdModal__Overlay {
    background-color: #0005;
    box-sizing: border-box;
    padding: 5px;
  }
  
  .IdModal__Container {
    background-color: white;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: column nowrap;
    border-radius: 10px;
    width: 100%;
    max-width: 780px;
  }
  
}