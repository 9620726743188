@import url('../../utils/color-palatte.css');

.Profile__Container {
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
  margin: auto;
  /* padding: 10px 0; */
  /* background-color: var(--FEDERAL_DASHBOARD_BG); */
  padding-bottom: 40px;
}

.Profile__Background__Conatiner {
  height: 200px;
  box-sizing: border-box;
  position: relative;
  /* padding: 10px; */
}

.Profile__Background__Image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
  position: absolute;
  /* background-size: auto;
  background-attachment: fixed; */
}
.Profile__Background__Ornament {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -45%;
  transform: scale(.8,.8);
}

.Profile__Content__Container {
  /* padding: 60px 30px; */
  padding-top: 60px;
  position: relative;
  box-sizing: border-box;
  /* box-shadow: 2px 2px 5px 3px #0004;
  margin-top: 13px;
  border-radius: 10px; */
}
.Profile__Content__User_Name {
  text-align: center;
  color: var(--FEDERAL_BLUE);
  margin-bottom: 0px;
}
.Profile__Content__UserName {
  text-align: center;
  color: var(--FEDERAL_BLUE);
  font-weight: normal;
  margin: 0;
  margin-top: 5px;
}
.Profile__Menu__Button {
  position: absolute;
  right: 40px;
  top: 20px;
}
.Profile__About__Container {
  border-color: var(--GRAY_SECOND_TRANSPARENCY_FULL);
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-right-width: 0;
  border-left-width: 0;
  border-style: solid;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
}
.Profile__About__Title {
  text-align: center;
  color: var(--GRAY_MAIN);
}
.Profile__About__Content {
  text-align: center;
  color: var(--GRAY_MAIN);
}
.Profile__Community__Container {
  background-color: var(--FEDERAL_BLUE);
  min-height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.Profile__Community__Header__Line {
  width: 80%;
  border-top-width: 1px;
  border-bottom-width: 0;
  border-color: var(--WHITE_MAIN);
  border-style: solid;
  height: 0;
  margin-top: 20px;
}
.Profile__Community__Header__Title {
  color: var(--WHITE_MAIN);
  margin: 0;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 10px;
}
.Profile__Community__Header__Line__Container {
  display: flex;
  align-content: center;
  height: 30px;
}
.Profile__Community__Picture {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border-width: 4px;
  border-color: var(--WHITE_MAIN);
  border-style: solid;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: cover;
}
.Profile__Community__Content__Container {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  padding: 10px;
}
.Profile__Community__Name__Container {
  display: flex;
  flex-direction: column;
}
.Profile__Community__Picture__Container {
  display: flex;
  flex-direction: row;
}
.Profile__Community__Name {
  color: var(--WHITE_MAIN);
  margin: 0;
  margin-left: 10px;
  /* margin-top: 5px; */
}
.Profile__Community__Rool {
  color: var(--FEDERAL_GREEN);
  margin: 0;
  margin-left: 10px;
  /* margin-top: 5px; */
}
.Profile__Community__Members {
  color: var(--WHITE_MAIN);
  /* margin: 0; */
  margin-right: 10px;
  /* margin-top: 5px; */
}
.Profile__Buttons__Container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  max-width: 400px;
  margin: auto;
}
.Profile__Action__Button {
  box-sizing: border-box;
  padding: 10px;
  border: none;
  background: transparent;
  position: relative;
  margin: 20px;
  cursor: pointer;
  width: 90px;
  height: 90px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  transition: all .3s;
  border-radius: 10px;
}
.Profile__Action__Button__Picture {
  width: 70px;
  height: 70px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.Profile__Action__Button__Number {
  color: var(--FEDERAL_BLUE);
  font-weight: normal;
  margin: 0;
}
.Profile__Action__Button__Name {
  color: var(--FEDERAL_BLUE);
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}
.Profile__Route__Button {
  width: 180px;
  background-color: var(--FEDERAL_BLUE);
  height: 40px;
  border: none;
  font-size: 1rem;
  font-family: 'Source Sans Pro', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row;
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 7px;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  color: white;
  margin: auto;
  margin-top: 20px;
  transition: all .2s;
  cursor: pointer;
}
.Profile__Route__Button:hover {
  background-color: var(--FEDERAL_BLUE_SOFT);
}

.Profile__Route__Button__Yellow {
  width: 180px;
  background-color: var(--WARNING_YELLOW);
  border: none;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 7px;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  color: var(--FEDERAL_BLUE);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 50px;
  transition: all .2s;
  cursor: pointer;
}
.Profile__Route__Button__Yellow:hover {
  background-color: var(--FEDERAL_LOW_BLUE);
}

.Profile__Position__Button__Container {
  position: absolute;
  left: 10px;
  top: 0px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.Profile__Activities__Button__Container {
  position: absolute;
  right: 10px;
  top: 0px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.Profile__Position__Button__Title {
  color: var(--FEDERAL_BLUE);
  text-transform: uppercase;
  margin: 0;
  margin-top: 10px;
}
.Profile__Activities__Button__Title {
  color: var(--FEDERAL_BLUE);
  text-transform: uppercase;
  margin: 0;
  text-align: right;
  margin-top: 10px;
}
.Profile__Position__Button__Position {
  color: var(--FEDERAL_BLUE);
  font-weight: normal;
  margin: 0;
  margin-top: 5px;
}
.Profile__Activities__Button__Activities {
  color: var(--FEDERAL_BLUE);
  font-weight: normal;
  margin: 0;
  margin-top: 5px;
  text-align: right;
  align-self: flex-end;
}

.cardContainer {
  width: 90%;
  border-radius: 10px;
  padding: 10px;
  align-self: center;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 20px;
}

.positionContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.positionTitle {
  font-size: 20px;
  font-family: 'Source Sans Pro', sans-serif;
  color: var(--FEDERAL_WHITE);
  margin: 10px;
}

.positionContent {
  font-size: 20px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  color: var(--FEDERAL_WHITE);
  margin: 0;
}

@media (max-width: 400px) {
  .Profile__Position__Button__Title {
    font-size: smaller;
  }
  .Profile__Activities__Button__Title {
    font-size: smaller;
  }
  .Profile__Position__Button__Position {
    font-size: smaller;
  }
  .Profile__Activities__Button__Activities {
    font-size: smaller;
  }
}
