.Download-Container {
  width: 100%;
  min-height: 100px;
  background-color: white;
  box-sizing: border-box;
  padding-top: 30px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}

.Download-Container h1 {
  font-family: 'Source Sans Pro';
  color: #04458f;
  font-size: 3em;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 8px;
  margin-bottom: 80px;
}

/* .Download-Container h1::before {
  position: absolute;
  content: '';
  width: 150px;
  height: 5px;
  background-color: var(--FEDERAL_BLUE_STRONG);
  bottom: 0px;
} */

.DownloadSubContainer {
  width: 100%;
  max-width: 1500px;
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-start;
  margin-top: -30px;
}

.DownloadImgContainer {
  width: 40%;
  height: inherit;
  position: relative;
  margin-bottom: -4px;
}

.DownloadImgContainer img {
  width: 100%;
}

.DownloadTextContainer {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding-right: 45px;
}

.DownloadTextContainer p {
  font-family: 'Source Sans Pro';
  font-size: 1.3em;
  text-align: start;
  margin-bottom: 20px;
  margin-top: 0;
  max-width: 600px;
}

.DownloadAppConainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: 60px;
}

.DownloadAppConainer a {
  height: 100%;
  margin: 20px;
}

.DownloadAppConainer a img {
  height: 100%;
  width: 210px;
}

@media (min-width: 768px) {
  .Download-Container h1 {
    margin-top: 100px;
  }
}

@media (max-width: 970px) {
  .DownloadAppConainer h1 {
    margin: 0;
  }

  .DownloadAppConainer a {
    margin: 20px;
  }
  .DownloadSubContainer {
    margin-top: -20px;
  }
}

/* @media (max-width: 500px) {
  .DownloadImgContainer img {
    margin-top: 80px;
    margin-left: -20% !important;
  }
} */
@media (max-width: 1050px) {
  .Download-Container h1 {
    font-size: 2em;
    padding-bottom: 5px;
    margin-bottom: -30px;
  }

  .DownloadSubContainer {
    width: 100%;
    flex-direction: column-reverse;
  }

  .DownloadImgContainer {
    width: 100%;
  }

  .DownloadImgContainer img {
    margin-top: 80px;
    margin-left: -30%;
  }

  .DownloadAppConainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    flex-direction: column;
    margin-bottom: 0px;
  }

  .DownloadTextContainer {
    width: 100%;
    height: 100%;
    align-items: center;
    flex-direction: column;
    padding: 45px;
    padding-bottom: 0;
  }

  .DownloadTextContainer p {
    margin-top: 50px;
    font-family: 'Source Sans Pro';
    font-size: 1.2em;
    text-align: center;
    margin-bottom: 50px;
  }

  .DownloadAppConainer a {
    margin: 20px;
  }
}
