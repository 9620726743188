@import url('../../utils/color-palatte.css');

.MessageInput__Container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row, nowrap;
  align-self: center;
}

.MessageInput {
  width: calc(100% - 120px);
  font-family: 'Source Sans Pro', sans-serif !important;
}

.MessageInput__Send-btn {
  border: none;
  background: transparent;
  cursor: pointer;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  border-radius: calc(50px / 2);
  transition: all .3s;
  overflow: hidden;
}

.MessageInput__Send-btn img{
  width: 100%;
  margin-left: 2px;
}