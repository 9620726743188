@import url(../../utils/color-palatte.css);

.IDCard__Container {
  width: 650px;
  border-radius: 20px;
  box-sizing: border-box;
  background: var(--FEDERAL_BLUE_STRONG);
}

.IDCard__Header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 20px;
  height: 45px;
  width: calc(100% + 6px);
  margin-left: -3px;
  margin-top: -3px;
}

.IDCard__Header img {
  width: 40px;
  margin-left: 10px;
  margin-right: 5px;
}

.IDCard__Content {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
}

.IDCard__Profile {
  width: 300px;
  height: inherit;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding-top: 30px;
}

.IDCard__Data-Container {
  width: calc(100% - 300px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  color: white;
  box-sizing: border-box;
  padding-top: 10px;
}

.IDCard__Data {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: row;
  box-sizing: border-box;
  padding-right: 20px;
}

.IDCard__Data p {
  text-transform: uppercase;
  font-size: 1.2em;
  margin: 10px 0;
}

.IDCard__Data p:nth-child(2) {
  text-align: right;
}

.IDCard__UserName {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
}

.IDCard__UserName p {
  text-transform: uppercase;
  font-size: 1.2em;
  margin-bottom: 0;
}

.IDCard__UserName h1 {
  margin: 0px;
  font-weight: normal;
}

.IDCard__NumberID {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column;
  box-sizing: border-box;
  padding-right: 20px;
  margin-bottom: 20px;
}

.IDCard__NumberID p {
  margin: 0;
  margin-bottom: 5px;
}

.IDCard__ID {
  width: 100%;
  background-color: white;
  color: var(--FEDERAL_BLUE_STRONG);
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 5px;
}

@media (max-width: 440px) {

  .IDCard__Data p {
    font-size: 1.5em;
  }

  .IDCard__NumberID p {
    font-size: 1.5rem;
  }

  .IDCard__UserName p {
    font-size: 1.5rem;
  }

  .IDCard__UserName h1 {
    font-size: 2rem;
  }
  
  .IDCard__ID {
    font-size: 1.5rem;
    border-radius: 20px;
  }

}