.PreviewModal__Overlay {
  background-color: #0004;
}

.PreviewModal__Container {
  width: 100%;
  max-width: 700px;
  height: 450px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  position: relative;
}

.PreviewModal__Media {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.PreviewModal__Close-btn {
  position: absolute;
  width: 25px;
  top: 0;
  right: 0;
  margin: 5px;
}

.PreviewModal__Img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  background-color: black;
}

.PreviewModal__Warning {
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  padding: 10px 15px;
  background-color: #FFFA;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  pointer-events: none;
}

.PreviewModal__Warning p {
  text-align: center;
  color: #c00;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  font-size: 1.4rem;
  margin: 0;
}

.PreviewModal__Warning p span {
  margin-top: 10px;
  color: #000;
}

.WebCamModal__Overlay {
  background-color: #0004;
}

.WebCamModal__Container {
  width: 100%;
  max-width: 800px;
  height: 450px;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  position: relative;
  border: 10px solid white;
}

.WebCamModal__Container video {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.WebCamModal__Btns {
  background-color: #000a;
  position: absolute;
  width: 100%;
  height: 100px;
  bottom: 0;
  border-radius: 0 0 8px 8px;
}

.WebCamModal__Action,
.WebCamModal__Switch {
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #0000;
  width: 60px;
  height: 60px;
  border-radius: calc(60px / 2);
  transition: all 0.2s;
  padding: 0;
}

.WebCamModal__Action {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.WebCamModal__Switch {
  left: 0;
  bottom: 50%;
  transform: translate(0, 50%);
  margin-left: 10px;
}

.WebCamModal__Action:hover,
.WebCamModal__Switch:hover {
  background-color: #fff8;
}

.WebCamModal__Action:active,
.WebCamModal__Switch:active {
  background-color: #fff8;
}

.WebCamModal__btn-pause {
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: space-around;
  align-items: center;
  padding: 0 5px;
}

.WebCamModal-pause {
  width: 25px;
  height: 25px;
  background-color: #EB2012;
  border-radius: 2px;
}

.WebCamModal__btn-active {
  width: 30px;
  height: 30px;
  background-color: #EB2012;
  border-radius: calc(30px / 2);
}

.WebCamModal__Switch img {
  width: 35px;
}

.WebCamModal__Container-mobile {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  flex-flow: column nowrap;
  overflow: hidden;
}

.WebCamModal__Close-btn {
  position: absolute;
  width: 25px;
  top: 0;
  right: 0;
  margin: 20px 20px 0 0;
}

.WebCamModal__Container-mobile video {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.WebCamModal__Container-mobile-weight {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 0;
  flex-flow: row nowrap;
  overflow: hidden;
}

.WebCamModal__Container-mobile-weight video {
  width: calc(100% - 100px);
  height: 100%;
  margin: 0;
  padding: 0;
}

.WebCamModal__Button-container {
  width: 100%;
  height: 160px;
  background-color: #555;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.WebCamModal__Mobile-action {
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  padding: 3px;
  border: 2px solid #fff;
  background: transparent;
  border-radius: calc(60px / 2);
  overflow: hidden;
  -webkit-tap-highlight-color: #0000;
}

.WebCamModal__Mobile-action:active > div {
  background: #c00;
}

.WebCamModal__Mobile-action div {
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 50%;
}

.WebCamModal__Mobile-action-recording div {
  background: #c00;
  animation: recording 1s linear infinite;
}

@keyframes recording {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.WebCamModal__Switch-mobile,
.WebCamModal__Switch-record-mobile {
  width: 60px;
  height: 60px;
  border-radius: calc(60px / 2);
  box-sizing: border-box;
  padding: 12px;
  background-clip: padding-box;
  border: none;
  background: transparent;
}

.WebCamModal__Switch-mobile img,
.WebCamModal__Switch-record-mobile img {
  width: 100%;
}

.WebCamModal__Show-error {
  position: absolute;
  text-align: center;
  color: #fff;
  margin-top: -100px;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.WebCamModal__Timer {
  height: 25px;
  min-width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0008;
  color: #fff;
  border-radius: calc(26px / 2);
  position: absolute;
  box-sizing: border-box;
  padding: 5px 8px;
  top: 0;
  left: 50%;
  margin-top: 20px;
  transform: translate(-50%, 0);
}
