@import '../color-palatte.css';

.Generaltextarea__Container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 5px 20px;
  box-sizing: border-box;
}

.GeneralTextArea {
  width: 100%;
  height: 250px;
  color: var(--FEDERAL_BLUE_SOFT);
  background-color: transparent;
  resize: none;
  border-radius: 8px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em;
  padding: 10px;
  outline: none;
}

.GeneralTextArea::placeholder {
  color: var(--FEDERAL_BLUE_SOFT);
}

.GeneralTextAreaDark {
  width: 100%;
  height: 250px;
  color: #FFF;
  background-color: transparent;
  resize: none;
  border-radius: 8px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em;
  padding: 10px;
  outline: none;
}

.GeneralTextAreaDark::placeholder {
  color: #FFF;
}

