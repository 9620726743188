.VotingList__Switch {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  box-sizing: border-box;
  margin: 20px 0;
  padding: 0 10px;
}

.VotingList__Btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 60px;
  border: none;
  box-sizing: border-box;
}

.VotingList__Btn img {
  width: 30px;
  margin-right: 10px;
}

.VotingList__Btn p {
  margin: 0;
  font-size: 1.2rem;
  font-family: 'Source Sans Pro', sans-serif;
}

@media (max-width: 500px) {
  .VotingList__Btn img {
    margin-right: 0;
  }
}