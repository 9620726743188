.container {
  text-align: center;
}

.container canvas {
  outline: none;
}

.btnVisibility {
    background: transparent;
    border: none;
    position: absolute;
    right: -10px;
    top: 10px;
}

.visibilityOnCSS {
  width: 25px; 
  height: 25px; 
  object-fit: contain; 
  cursor: pointer;
}

.input-container {
  position: relative;
  max-width: 300px;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}