@import url('../../utils/color-palatte.css');

.Notification__Container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: -1px 0;
  box-sizing: border-box;
  padding: 10px;
  flex-flow: column nowrap;
  border: 1px solid #2573b460;
  cursor: pointer;
  position: relative;
}

.Notification__Container:hover {
  border-color: var(--FEDERAL_BLUE);
}

.NotificationItem__Content {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
}

.NotificationItem__ImageContainer {
  display: flex;
  margin-right: 10px;
  width: 60px;
}

.NotificationItem__Icon {
  width: 60px;
  box-sizing: border-box;
  padding: 10px;
}

.NotificationItem__Title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column nowrap;
  margin: 0;
}

.NotificationItem__Menu {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 15px;
  margin-right: 15px;
}

