@import url('../../../utils/color-palatte.css');

.Reliability__Container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  width: 100%;
  background-color: #FFF;
  position: relative;
  box-sizing: border-box;
  padding: 10px;
}

.Reliability__Title {
  width: 100%;
  max-width: 400px;
  font-size: 3rem;
  color: var(--FEDERAL_BLUE_STRONG);
  position: relative;
  margin-bottom: 50px;
}

.Reliability__ArtWork {
  width: 100%;
  position: relative;
  height: 700px;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 100px;
}

.Reliability__Dialog__Regular {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 5%;
  margin-right: 19%;
  width: 240px;
  height: 380px;
  box-sizing: border-box;
  padding: 5px 8px;
  color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Reliability__TextContent {
  background-color: var(--FEDERAL_BLUE);
  color: #FFF;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  padding: 20px 40px;
  border-radius: 20px;
  box-shadow: 0 3px 6px #0008;
  position: relative;
  margin-bottom: 60px;
}

.Reliability__Arrow {
  position: absolute;
  width: 100px;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  margin-bottom: -60px;
  margin-left: 50px;
}

.Reliability__Hero {
  width: 100%;
  object-fit: contain;
  max-width: 350px;
}

@media (max-width: 500px) {
  
  .Reliability__Hero {
    width: 200px;
  }
}

@media (max-width: 380px) {
  
  .Reliability__TextContent {
    padding: 20px;
  }

}