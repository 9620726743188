@import url('../../utils/color-palatte.css');

.Followers__Container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  width: 100%;
}

.Followers__SearchInput {
  width: calc(100% - 40px) !important;
  max-width: 800px;
}