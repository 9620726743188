@import url('../../color-palatte.css');

.Regular__Input-Container1 {
  width: 100%;
  padding: 5px 0px;
  box-sizing: border-box;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Regular__Input1 {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 12px;
  color: var(--FEDERAL_BLUE);
  background-color: transparent;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em;
}

.Regular__Input1:-webkit-autofill,
.Regular__Input1:-webkit-autofill:hover,
.Regular__Input1:-webkit-autofill:focus,
.Regular__Input1:-internal-autofill-selected,
.Regular__Input1:-internal-autofill-selected:hover,
.Regular__Input1:-internal-autofill-selected:focus {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 12px;
  color: var(--FEDERAL_BLUE) !important;
  -webkit-text-fill-color: var(--FEDERAL_BLUE) !important;
  background-color: #E6E6E6;
  box-shadow: 0 0 0 30px #E6E6E6 inset !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em !important;
}

.Regular__Input1::placeholder {
  color: var(--FEDERAL_BLUE);
  /* For Firefox Browser */
  opacity: 1;
}

.Regular__Input-white1 {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 12px;
  color: white;
  background-color: transparent;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em;
}

.Regular__Input-white1:-webkit-autofill,
.Regular__Input-white1:-webkit-autofill:hover,
.Regular__Input-white1:-webkit-autofill:focus,
.Regular__Input-white1:-internal-autofill-selected,
.Regular__Input-white1:-internal-autofill-selected:hover,
.Regular__Input-white1:-internal-autofill-selected:focus {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 12px;
  color: #FFF !important;
  -webkit-text-fill-color: #FFF !important;
  background-color: var(--FEDERAL_BLUE) !important;
  box-shadow: 0 0 0 30px var(--FEDERAL_BLUE) inset !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-size: 1.1em !important;
  caret-color: #FFF !important;
}

.Regular__Input-white1::placeholder {
  color: white;
  opacity: 1;
}
