.WhitePaper__Container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
  min-height: 100vh;
}

.Menu__Item {
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
  padding: 5px;
  box-sizing: border-box;
  margin-bottom: -2px;
  box-sizing: border-box;
  background-clip: padding-box;
  width: 100%;
  cursor: pointer;
}

.Menu__Container {
  width: 300px;
  max-width: 300px;
  min-height: 100vh;
  height: 100%;
  top: 0;
  left: 0;
  position: sticky;
}

.SideMenu__Container {
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  padding-left: 40px;
}

.SideMenu__Mobile__Container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
  padding-left: 40px;
  height: 100%;
  overflow: auto;
}

.WhitePaper__Content {
  width: calc(100% - 600px);
  box-sizing: border-box;
}