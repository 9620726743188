.Anim__Container {
  width: 100%;
  height: 100%;
  position: relative;
}

.Anim__Content {
  position: absolute;
  width: 100px;
  height: inherit;
  left: 50%;
  transform: translate(-50%, 0);
}

.Anim1__Ball1 {
  width: 20px;
  position: absolute;
  background-color: white;
  height: 20px;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  border-radius: 20px;
  animation: Anim1 .5s infinite;
}
.Anim1__Ball2 {
  width: 20px;
  position: absolute;
  background-color: white;
  height: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  animation: Anim1 .5s infinite;
  animation-delay: .1s;
}
.Anim1__Ball3 {
  width: 20px;
  position: absolute;
  background-color: white;
  height: 20px;
  top: 50%;
  right: 0%;
  transform: translate(0, -50%);
  border-radius: 20px;
  animation: Anim1 .5s infinite;
  animation-delay: .2s;
}

@keyframes Anim1 {

  0%, 100% {
    margin-top: 5px;
  }
  
  50% {
    margin-top: -10px;
    opacity: .7;
  }
}

.AppLoader {
  position: relative;
}
.AppLoader svg {
  position: absolute;
  animation: loaderSpin .6s linear infinite
}

@keyframes loaderSpin {
  0% {
    transform: rotate(0deg)
  } 100% {
    transform: rotate(360deg);
  }
}