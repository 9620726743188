.NotificationHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  padding: 5px;
}

.RemoveAll__Btn {
  appearance: none;
  -webkit-appearance: none;
  background: #0000;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 20px;
  cursor: pointer;
}

.RemoveAll__Btn img {
  width: 30px;
}