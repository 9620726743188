.Ornament__Container {
  width: 250px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
}

.Ornament__Area {
  width: inherit;
  position: relative;
  height: 198px;
}

.Ornament {
  width: 100%;
  height: 100%;
}

.Ornament__Profile-border {
  position: absolute;
  width: 150px;
  height: 150px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  margin-top: 5px;
  overflow: hidden;
}

.Ornament__Profile {
  width: 100%;
}

.Ornament__UserName {
  font-size: 1.3rem;
  margin: 2px 0;
}

.Ornament__UserRank-small {
  font-size: 1.1em;
  font-weight: bold;
  margin: 0;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.Ornament__UserRank-big {
  font-size: 1.5em;
  text-transform: uppercase;
  margin: 0;
  margin-top: 10px;
}