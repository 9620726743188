@import url('../../../utils/color-palatte.css');

.Home__Stats {
  width: 100%;
  min-height: 600px;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  padding: 20px 0;
  padding-bottom: 40px;
  margin-bottom: -1px;
}

.Home__Stats h1 {
  font-family: 'Source Sans Pro';
  position: relative;
  color: var(--FEDERAL_BLUE);
  width: 120px;
  display: flex;
  justify-content: center;
  font-size: 3em;
  padding: 8px 20px;
  margin: 0;
}

.Home__Stats-divider {
  width: 80%;
  max-width: 800px;
  height: 0;
  border-bottom: 2px solid var(--FEDERAL_BLUE);
}

.Home__Stats-p {
  font-family: "Source Sans Pro";
  font-size: 1.2em;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 0 10px;
}

.Home__Stats-leaderboard {
  width: 100%;
  max-width: 850px;
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  align-items: flex-start;
  margin: 20px 0;
  flex-wrap: wrap;
}

.Cards-container {
  display: flex;
  width: 80%;
  box-sizing: border-box;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1000px;
}

@media (max-width: 500px) {

  .Cards-container {
    width: 100%;
  }

  .Home__Stats h1 {
    font-size: 2em;
    padding-bottom: 5px;
  }

  .Home__Stats-leaderboard {
    flex-flow: column;
    align-items: center;
  }
  
}