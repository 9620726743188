@import '../../../utils/color-palatte.css';

.Question__Container {
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
  background-color: var(--FEDERAL_WHITE2);
}

.Question__h1 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 900;
  font-size: 1.5em;
  color: var(--FEDERAL_BLUE_STRONG);
  margin: 0;
}

.Question__Paragraph {
  font-family: 'Source Sans pro', sans-serif;
  font-size: 1em;
}

.Question__Paragraph span {
  color: var(--FEDERAL_BLUE);
}

.Question__Window-Container {
  width: 100%;
  margin: 40px 0;
}

.Question__Window-Header {
  background-color: var(--FEDERAL_GRAYED_BLUE);
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
  border-radius: 15px 15px 0 0;
  border-bottom: 2px solid white; 
}

.Question__Window-Header p {
  color: var(--FEDERAL_BLUE_SOFT);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.5em;
  font-weight: 700;
}

.Question__Window-content {
  width: 100%;
  min-height: 100px;
  border-radius: 0 0 15px 15px;
  background-color: var(--FEDERAL_BLUE_SOFT);
  box-sizing: border-box;
  padding: 30px;
}

.Question__Window-Paragraph {
  margin: 0;
  color: white;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em;
  padding: 10px 0;
}