.ChangeEmailModal__Overlay {
  box-sizing: border-box;
  padding: 5px;
  background-color: #0008;
}

.ChangeEmailModal__Container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 800px;
  overflow: auto;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 10px;
  max-height: calc(100vh - 10px);
}

.ChangeEmailModal__Container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.ChangeEmailModal__CloseBtn {
  width: 25px;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 15px;
  margin-right: 15px;
}

.ChangeEmailModal__Title {
  margin-bottom: 0px;
  margin-top: 20px;
  width: 100%;
  text-align: center;
}

.ChangeEmailModal__Icon {
  width: 100px;
}

.ChangeEmailModal__Form {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column nowrap;
  width: calc(100% - 100px);
  max-width: 500px;
}

.ChangeEmailModal__Button {
  width: 100%;
  height: 35px;
  border: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1rem;
  border-radius: 5px;
}

@media (max-width: 500px) {

  .ChangeEmailModal__Title {
    font-size: 1.7rem;
  }
  
  .ChangeEmailModal__Icon {
    width: 85px;
  }
  
  .ChangeEmailModal__Form {
    width: 100%;
  }

}