@import url('../color-palatte.css');

.SendButton {
  background: var(--FEDERAL_BLUE);
  color: white;
  border-radius: 8px;
  width: 300px;
  height: 50px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 2em;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
}