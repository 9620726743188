@import url(../../../utils/color-palatte.css);

.InfluencerContainer {
  background-color: #ffffff;
  width: 100%;
  min-height: 480px;
  box-sizing: border-box;
  padding: 20px;
  padding-top: 40px;
  position: relative;
}

.CardsInfluencer {
  width: 100%;
  height: 370px;
  margin: 15px;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  max-width: 1100px;
}

.influencerOpinion {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  position: relative;
}

.influencerOpinion img {
  width: 300px;
  pointer-events: none;
}

.influencerOpinion p {
  position: absolute;
  width: 70%;
  text-align: end;
  font-size: 2.1em;
  top: 0;
  right: 0;
  margin-top: 20px;
  margin-right: 20px;
  color: #2773b4;
}

.influencerOpinion p span {
  font-size: .8em;
  font-weight: bold;
}

.InfluencerContainer svg {
  width: 100%;
  height: 100px;
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: -1px;
}

.diagonal2 {
  fill: #2573b4;
  stroke: none;
}

.InfluencerCarousel__arrow {
  height: 100%;
}

.InfluencerCarousel__btn {
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  align-self: center;
  margin: 0;
  cursor: pointer;
}

.InfluencerCarousel__btn:disabled {
  opacity: .5;
  cursor: default;
}

@media (max-width:850px) {

  .InfluencerContainer {
    min-height: 440px;
  }

  .CardsInfluencer {
    height: 350px;
  }

  .influencerOpinion p {
    font-size: 1.7em;
  }
}

@media (max-width:700px) {

  .InfluencerContainer {
    min-height: 400px;
    padding: 5px;
    padding-top: 40px;
  }

  .CardsInfluencer {
    height: 450px;
  }

  .influencerOpinion {
    flex-direction: column-reverse;
  }

  .influencerOpinion p {
    font-size: 1.3em;
    text-align: center;
    margin: 0;
    width: 100%;
  }
}

@media (max-width:500px) {

  .InfluencerContainer {
    min-height: 300px;
    padding: 5px;
    padding-top: 40px;
  }

  .CardsInfluencer {
    height: 400px;
  }

  .influencerOpinion img {
    width: 220px;
  }

  .influencerOpinion p {
    font-size: 1.3em;
    text-align: center;
  }
}

@media (max-width:380px) {

  .InfluencerContainer {
    height: 500px;
    padding: 5px;
    padding-top: 40px;
  }

  .CardsInfluencer {
    height: 440px;
  }

  .influencerOpinion img {
    width: 200px;
  }

  .influencerOpinion p {
    font-size: 1.3em;
    text-align: center;
  }
}