@import url('../../color-palatte.css');

.PasswordInput__Container {
  width: 100%;
  padding: 5px 0px;
  box-sizing: border-box;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.PasswordInput {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 12px;
  color: var(--FEDERAL_BLUE);
  background-color: transparent;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em;
}

.PasswordInput::placeholder {
  color: var(--FEDERAL_BLUE);
  /* For Firefox Browser */
  opacity: 1;
}

.PasswordInput-white {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 12px;
  color: white;
  background-color: transparent;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em;
}

.PasswordInput-white::placeholder {
  color: white;
  opacity: 1;
}

.PasswordInput__Image {
  position: absolute;
  right: 0;
  width: 30px;
  margin-right: 10px;
}