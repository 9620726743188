@import url('../../color-palatte.css');

.Regular__TextArea-Container {
  width: 100%;
  padding: 5px 0px;
  box-sizing: border-box;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Regular__TextArea {
  width: 100%;
  min-height: 100px;
  box-sizing: border-box;
  padding: 5px 10px;
  border-radius: 12px;
  color: var(--FEDERAL_BLUE);
  background-color: transparent;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em;
  resize: none;
}

.Regular__TextArea::placeholder {
  color: var(--FEDERAL_BLUE);
  /* For Firefox Browser */
  opacity: 1;
}

.Regular__TextArea-white {
  width: 100%;
  min-height: 100px;
  box-sizing: border-box;
  padding: 5px 10px;
  border-radius: 12px;
  color: white;
  background-color: transparent;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em;
  resize: none;
}

.Regular__TextArea-white::placeholder {
  color: white;
  opacity: 1;
}