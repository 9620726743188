@import url('../../utils/color-palatte.css');

.NewChievModal__Overlay {
  background-color: #0008;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  box-sizing: border-box;
  padding: 10px;  
}

.NewChievModal__Container {
  display: flex;
  width: 100%;
  max-width: 700px;
  border-radius: 10px;
  box-sizing: border-box;
  position: relative;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
}

.NewChievModal__Title {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  padding: 10px 0;
  margin-bottom: 10px;
}

.NewChievModal__Title img {
  width: 30px;
}

.NewChievModal__Title h1 {
  text-align: center;
  margin: 0 15px;
}

.NewChievModal__Content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  box-sizing: border-box;
  padding: 0 20px;
}

.NewChievModal__Button {
  border: none;
  border-radius: 7px;
  background-color: var(--FEDERAL_BLUE);
  color: var(--WHITE_MAIN);
  height: 35px;
  width: calc(100% - 40px);
  margin: 20px 0 10px 0;
  font-family: 'Source Sans Pro', sans-serif;
  transition: all .15s;
  font-size: 1.1rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.NewChievModal__Button:hover {
  background-color: var(--FEDERAL_BLUE_SOFT);
}