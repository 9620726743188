@import url('../../utils/color-palatte.css');

.Service__Content {
  width: 100%;
  max-width: 700px;
  min-height: 200px;
  background: linear-gradient(180deg, var(--FEDERAL_BLUE_SOFT), var(--FEDERAL_BLUE));
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  margin: 20px auto;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 10px;
}

.Service__Header {
  border-bottom: 2px solid white;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row nowrap;
  color: white;
}

.Service__Icon {
  left: 0;
  top: 0;
}

.Service__Img {
  width: 90px;
  height: 90px;
  margin-right: 15px;
}

.Service__Text {
  width: calc(100% - 105px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column nowrap;
}

.Service__Text h1 {
  margin: 0;
}

.Service__Text p {
  margin: 0 0 20px 0;
}

.Service__Content h1 {
  margin: 0;
}

.Service__Foot {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
  flex-flow: row nowrap;
  min-height: 100px;
}

.Service__User {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.Service__UserInf {
  color: white;
  margin: 0;
  width: calc(100% - 120px);
  margin-left: 15px;
}

.Service__UserInf p {
  margin: 0;
}

.Service__Btn {
  background-color: var(--WARNING_YELLOW);
  color: var(--FEDERAL_BLUE);
  border: none;
  width: 110px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  margin-top: 20px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
}

.Service__Btn:hover {
  background-color: var(--FEDERAL_LOW_BLUE);
}

.Service__Btn2 {
  background-color: var(--FEDERAL_LOW_BLUE);
  color: var(--FEDERAL_BLUE);
  border: none;
  width: 110px;
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
}

.Service__Btn2:hover {
  background-color: var();
}

@media (max-width:600px) {
  .Service__Header {
    flex-flow: column nowrap;
    align-items: center;
  }

  .Service__Text {
    width: 100%;
  }
}