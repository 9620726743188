.UserItem__Container {
  width: calc(100% - 20px);
  height: 85px;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
  max-width: 850px;
  margin: 10px;
}

@media (max-width: 600px) {
  
  .UserItem__Container {
    height: 65px;
    padding: 5px;
  }
}