@import url('../../utils/color-palatte.css');

.UserProfile__Container {
  width: 60px;
  height: 60px;
  border-radius: calc(60px / 2);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  -webkit-tap-highlight-color: #0000;
}

.UserProfile__Img {
  width: 100%;
  height: auto;
  min-height: 100%;
  border-radius: inherit;
  background-clip: padding-box;
  box-sizing: border-box;
}

/* ----------------- */

.UserProfile__Username-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row;
  -webkit-tap-highlight-color: #0000;
}

.UserProfile__CrownIcon {
  width: 20px;
}

.UserProfile__Username {
  margin: 0;
  font-size: 1.8em;
}

@media (max-width: 600px) {

  .UserProfile__Username {
    font-size: 1.3em;
  }
  
}

@media (max-width: 450px) {

  .UserProfile__Username {
    font-size: 1em;
  }
  
}