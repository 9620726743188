@import url('../../../utils/color-palatte.css');

.PaymentWarnnig__Overlay {
  background: #0008;
  box-sizing: border-box;
  padding: 5px;
}

.PaymentWarning__Header {
  background: transparent;
  width: 100%;
}

.PaymentWarning__Header h1 {
  color: var(--WARNING_YELLOW);
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
  position: relative;
  box-sizing: border-box;
  padding: 0 10px;
}

.PaymentWarning__Header h1::before {
  content: '';
  position: absolute;
  width: calc(100% - 100px);
  height: 3px;
  background: linear-gradient(
    90deg,
    transparent,
    var(--WARNING_YELLOW),
    transparent
  );
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  margin-bottom: -10px;
}

.PaymentWarnnig__Container {
  background-color: var(--FEDERAL_BLACK_LIGHT);
  color: #fff;
  width: 100%;
  max-width: 750px;
  min-height: 300px;
  border-radius: 10px;
  background-image: url(../../../assets/payment-modals/gg_grey_bg.svg);
  background-size: 130%;
  background-repeat: no-repeat;
  background-position: 50% 40%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  overflow: overlay;
}

.PaymentWarnnig__Container::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.PaymentWarnnig__Container h3 {
  width: 100%;
  text-align: center;
  position: relative;
}

.PaymentWarnnig__Container h3::before {
  content: '';
  position: absolute;
  width: calc(100% - 30px);
  height: 3px;
  background: linear-gradient(
    90deg,
    transparent,
    var(--FEDERAL_WHITE),
    transparent
  );
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  margin-bottom: -10px;
}

.PaymentWarning__Close-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    margin: 10px 10px 0 0;
    cursor: pointer;
}

.PaymentWarnnig__Content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
}

.PaymentWarning__btn1 { 
  width: 60%;
  background: var(--WARNING_YELLOW);
  color: var(--FEDERAL_BLUE);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 15px auto;
  border: none;
  border-radius: 5px;
  padding: 8px 20px;
  text-decoration: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1rem;
  transition: all 0.3s;
  cursor: pointer;
}

.PaymentWarning__btn2  {
width: 60%;
background: var(--FEDERAL_BLUE);
color: #fff;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
margin: 0 0 35px 0;
border: none;
border-radius: 5px;
padding: 8px 20px;
text-decoration: none;
font-family: 'Source Sans Pro', sans-serif;
font-size: 1.1rem;
transition: all 0.3s;
cursor: pointer;
}

.PaymentWarning__btn1:hover {
background: var(--FEDERAL_LOW_BLUE);
}

.PaymentWarning__btn2:hover {
background: var(--FEDERAL_BLUE_SOFT);
}

@media (max-width: 600px) {
  .PaymentWarnnig__Container {
    height: 500px;
  }
  .PaymentWarning__Header {
    font-size: 0.7rem;
  }
  .PaymentWarning__Li {
    font-size: 0.845rem;
  }
}