@import url('../../../utils/color-palatte.css');

.Home-Banner {
  width: 100%;
  background: url(../../../assets/home/banner/earth-picture.png);
  background-size: cover;
  background-position: top center;
  color: white;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 60px;
  box-sizing: border-box;
}

.Home-Banner h1{
  font-family: 'Source Sans Pro';
  font-size: 4em;
  margin: 0;
  margin-top: 50px;
}

.Home-Banner p {
  margin-top: 5px;
  margin-bottom: 30px;
  font-family: 'Source Sans Pro';
  width: 55%;
  font-size: 1.7em;
  line-height: 30px;
  max-width: 800px;
}

.Home__Banner-buttonArea {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 700px;
  /* padding: 0 20px; */
  align-items: center;
  flex-wrap: wrap;
}

.Home__Banner__DownloadArea {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 700px;
  /* padding: 0 20px; */
  align-items: center;
  flex-wrap: wrap;
}

.Home__Banner-Button {
  color: white;
  padding: 10px 20px;
  background: var(--FEDERAL_BLUE);
  border-radius: 8px;
  margin: 20px;
  min-width: 215px;
}

.Home-Banner svg {
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  margin-bottom: -1px;
  right: 0;
  margin-right: 0;
}

.diagonal1 {
  fill: #fff;
  stroke: none;
}

@media (min-width: 1200px) {
  .Home-Banner {
    max-height: 800px;
  }
}

@media (max-width:695px) {
  .Home-Banner h1{
    margin-top: 40px;
    font-size: 3em;
  }

  .Home-Banner p{
    margin-top: 5px;
    width: 65%;
    font-size: 1.3em;
    line-height: 20px;
  }

  .Home-Banner a p {
    font-size: 1.2em;
    background-color: #f8e84d;
    font-weight: bold;
    width: 280px;
    height: 55px;
    border-radius: 8px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width:400px) {

  .Home-Banner h1{
    margin-top: 40px;
    font-size: 1.6em;
  }

  .Home-Banner p{
    margin-top: 5px;
    width: 70%;
    font-size: 1em;
    line-height: 20px;
  }

  .Home-Banner a p {
    font-size: 1.2em;
    background-color: #f8e84d;
    font-weight: bold;
    width: 200px;
    height: 40px;
    border-radius: 8px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}