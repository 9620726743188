@import url('../../utils/color-palatte.css');

.NotLoadedRss__Container {
  background-color: var(--WARNING_RED_TRANSPARENCY);
  padding: 0 20px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  height: 35px;
  box-sizing: border-box;
  border-radius: calc(35px / 2);
}

.NotLoadedRss__Container img {
  width: 40px;
  margin-right: 10px;
  margin-top: 5px;
}

.NotLoadedRss__Container p {
  margin: 0px;
  color: #FFF;
  margin-right: 10px;
}