@import url('../../utils/color-palatte.css');

.login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--SPACE);
  /* background-image: url('../../assets/general/general_background.svg'); */
  background-image: url('../../assets/log-in/login-bg.png');
  background-repeat: no-repeat;
  background-position: 100% 100%;
}

.Login__Container {
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  z-index: 1;
}

.world-image-bg {
  position: absolute;
  width: 80vw;
  height: 80vh;
  max-width: 500px;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto;
  z-index: 1; 
}

.Login__H1 {
  color: white;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.login__BG {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 510px;
  z-index: 0;
}

.Login__Input {
  border-radius: 10px;
  margin: 10px 0;
  width: 100%;
  background: none;
}

.login__forgot {
  font-family: 'Source Sans Pro', sans-serif;
  color: white;
  min-width: 100%;
  font-size: 1.1em;
  cursor: pointer;
  transition: all .2s;
  -webkit-tap-highlight-color: transparent;
}

.login__forgot:hover {
  color: #F8E84D;
}

.login__button {
  border: none;
  width: 140px;
  height: 42px;
  margin: 25px auto 20px auto;
  background: white;
  color: #2573B4;
  font-size: 1.1em;
  border-radius: 7px;
  cursor: pointer;
  transition: all .3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login__button:hover {
  background: #F8E84D;
  transform: scale(1.1, 1.1);
}

.login__sing-up {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row;
  flex-wrap: wrap;
}

.login__sing-up p {
  color: white;
  font-size: 1.1em;
}

.login__sing-up-Btn {
  font-size: 1.1em;
  color: #F8E84D;
  margin-left: 8px;
  cursor: pointer;
  transition: all .3s;
}

.login__sing-up-Btn:hover {
  color: white;
}

.bgMobile {
width: 100%;
height: 100%;
z-index: 0;
position: absolute;
object-fit: cover;
}

@media (max-width: 750px) {
  
  .login {
    background-image: none;
  }
  
  .login {
    background-size: 80%;
  }

}

@media (max-width: 500px) {
  .world-image-bg {
    height: 40vh;
  }
  .login {
    box-sizing: border-box;
    padding: 0 10px;
  }

  .Login__Container {
    transform: scale(.85, .85);
  }

}