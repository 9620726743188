.Terms__Container {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  box-sizing: border-box;
  padding: 0 40px;
  padding-bottom: 60px;
  margin: 0 auto;
}