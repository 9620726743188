.Modal {
  position: fixed;
}

.overlay { 
  width: 100%; 
  height: 100vh;
  position: fixed;
  top: 0; 
  left: 0;
  z-index: 15; 
  display: flex; 
  justify-content: center; 
  align-items: center;
} 
 
.Modal__container { 
  position: relative;
  z-index: 16; 
}