.ErrorMailBox {
  background: url(../../assets/mail-error/BackgroundGray.svg);
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 700px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: 'Source Sans Pro';
  color: white;
  box-sizing: border-box;
  padding: 50px 0;
}

.ErrorContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 150px;
}

.ErrorContainer img{
  width: 200px;
  margin-right: 20px;
  margin-left: 200px;
}

.ErrorText {
  width: 60%;
}

.ErrorText h1{
  font-size: 5em;
  margin: 0;
  width: 100%;
}

.ErrorContainer p{
  font-size: 1.7em;
  margin: 0;
  width: 70%;
}

.ErrorMailBox svg {
  width: 100%;
  height: 120px;
  position: absolute;
  bottom: 0;
}

.diagonal1 {
  fill: #fff;
  stroke: none;
}

@media (max-width: 930px) {
  .ErrorText h1{
    font-size: 4em;
  }

  .ErrorText p{
    font-size: 1.7em;
    margin: 0;
    width: 80%;
  }
}

@media (max-width: 840px) {
  .ErrorMailBox {
    background: url(../../assets/mail-error/GrayBG2l.svg);
    background-size: cover;
    background-position: center;
  }
  .ErrorContainer {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .ErrorContainer img{
    width: 200px;
    margin: 0;
    margin-left: 50px;
    margin-bottom: 30px;
  }
  .ErrorText{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    box-sizing: border-box;
    margin: 0 10px;
  }
  .ErrorText h1{
    font-size: 4em;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
  }
  .ErrorText p{
    text-align: center;
    margin: 0;
    width: 100%;
  }
}

@media (max-width: 500px) {
  .ErrorContainer img{
    width: 140px;
    margin: 0;
    margin-left: 40px;
    margin-bottom: 30px;
  }
  .ErrorText h1{
    font-size: 2.5em;
    text-align: center;
    width: 100%;
  }
  .ErrorText p{
    text-align: center;
    margin: 0;
    width: 100%;
  }
}