@import url('../../color-palatte.css');

.SelectInput__Container {
  width: 100%;
  padding: 5px 0px;
  box-sizing: border-box;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SelectInput {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 12px;
  color: var(--FEDERAL_BLUE);
  background-color: transparent;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em;
  outline: none;
  background-image: url(../../../assets/app-inputs/select-input/icon_input_showdown_selector.svg);
  background-size: 30px;
  background-position: calc(100% - 5px) center;
  background-repeat: no-repeat;
}

.SelectInput::placeholder {
  color: var(--FEDERAL_BLUE);
}

.SelectInput-white {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 12px;
  color: white;
  background-color: transparent;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em;
  outline: none;
  background-image: url(../../../assets/app-inputs/select-input/icon_input_showdown_selector_white.svg);
  background-size: 30px;
  background-position: calc(100% - 5px) center;
  background-repeat: no-repeat;
}

.SelectInput-white::placeholder {
  color: white;
}

#SelectInput-white * {
  background-color: var(--UNSATURATED_BLACK_BLUE);
  color: #FFF;
}