.PreviewChievModal__Overlay {
  background-color: #0008;
  padding: 10px;
  box-sizing: border-box;
}

.PreviewChievModal__Conainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  padding: 15px;
  border-radius: 10px;
  position: relative;
}

.PreviewChievModal__Image {
  object-fit: scale-down;
  /* background-color: #0009; */
  width: 100%;
  height: 100%;
  border-radius: 3px;
}

.PreviewChievModal__CloseBtn {
  width: 30px;
  top: 0;
  right: 0;
  margin: 20px 20px 0 0;
  position: absolute;
  cursor: pointer;
}