@import url('../../utils/color-palatte.css');

.RestorePassword__Container {
  width: 100%;
  min-height: 100vh;
  background-color: var(--FEDERAL_BLUE);
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/log-in/login-bg.png');
  background-repeat: no-repeat;
  background-position: right bottom;
}

.RestorePassword__Form {
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column; 
  padding: 20px; 
}

.RestorePassword__Button {
  width: 150px;
  height: 40px;
  color: var(--FEDERAL_BLUE);
  background: var(--FEDERAL_LOW_BLUE);
  border: none;
  border-radius: 8px;
  font-size: 1.1em;
  font-family: 'Source Sans Pro', sans-serif;
  cursor: pointer;
  transition: all .3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RestorePassword__Button:hover {
  background: var(--WARNING_YELLOW);
  transform: scale(1.1, 1.1);
}