@import url('../../utils/color-palatte.css');

.InfoModal__Overlay {
  background: #0005;
  overflow: hidden;
}

.InfoModal__Container {
  background-color: var(--FEDERAL_BLUE);
  width: 550px;
  padding: 15px 20px;
  padding-right: 15px;
  box-sizing: border-box;
  box-shadow: 3px 3px 5px 3px #0005;
  border-radius: 8px;
  position: relative;
  max-height: calc(100vh - 10px);
}

.InfoModal__Content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.infoModal__ImgContainer {
  width: 160px;
  position: relative;
}

.infoModal__ImgContainer img {
  width: 145px;
  margin-left: -10px;
  margin-bottom: 0px;
  margin-top: -10px;
}

.infoModal__ImgContainer img:nth-child(2) {
  width: 80px;
  position: absolute;
  right: 0;
  margin: 0;
}

.InfoModal__DescriptionContainer {
  width: calc(100% - 170px);
  background: white;
  min-height: 190px;
  border-radius: 0 10px 10px 10px;
  box-shadow: -3px 3px 3px 0px #0005;
  z-index: 1;
  padding: 10px 15px;
  max-height: calc(100vh - 120px);
  overflow: hidden;
}

.InfoModal__DescriptionContainer p {
  overflow: auto;
  max-height: calc(100vh - 200px);
}
.InfoModal__DescriptionContainer p::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.InfoModal__Header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  padding: 0px 5px 12px 0px;
  box-sizing: border-box;
  border-bottom: 2px solid var(--FEDERAL_BLUE_STRONG);
}

.InfoModal__Header img {
  height: 40px;
  margin-right: 15px;
}

.InfoModal__Header h2 {
  width: calc(100% - 50px);
  text-transform: uppercase;
  color: var(--FEDERAL_BLUE_STRONG);
}

.InfoModal__Description p {
  margin: 10px 0 0 0;
}

.InfoModal__ButtonContainer {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
}

.InfoModal__ButtonContainer button[type='button'] {
  position: absolute;
  color: white;
  border: none;
  background: var(--FEDERAL_BLUE_SOFT);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: .9em;
  padding: 8px 35px;
  bottom: 1px;
  border-radius: 5px;
  box-shadow: 1px 1px 3px 0px #0004;
  cursor: pointer;
  transition: all .2s;
}

.InfoModal__ButtonContainer button[type='button']:hover {
  background: var(--FEDERAL_LIGHT_BLUE);
  color: var(--FEDERAL_GRAYED_BLUE);
}

.InfoModal__ButtonContainer button[type='button']:active {
  transform: scale(.8, .8);
  background: var(--FEDERAL_GRAYED_BLUE);
  color: white;
}

@media (max-width: 500px) {

  .InfoModal__Overlay {
    padding: 10px;
    box-sizing: border-box;
  }


  .infoModal__ImgContainer {
    width: 130px;
  }
  
  .infoModal__ImgContainer img {
    width: 115px;
  }
  
  .infoModal__ImgContainer img:nth-child(2) {
    width: 70px;
  }

  .InfoModal__DescriptionContainer {
    min-height: 140px;
    width: calc(100% - 130px);
  }

  .InfoModal__Header {
    height: 35px;
    padding: 0px 5px 12px 0px;
  }
  
  .InfoModal__Header img {
    height: 30px;
    margin-right: 15px;
  }
  
  .InfoModal__Header h2 {
    font-size: 1.2em;
  }
  
  .InfoModal__Description p {
    font-size: .8em;
  }

}

@media (max-width: 440px) {

  .InfoModal__Overlay {
    padding: 10px;
    box-sizing: border-box;
  }


  .infoModal__ImgContainer {
    width: 100px;
  }
  
  .infoModal__ImgContainer img {
    width: 105px;
  }
  
  .infoModal__ImgContainer img:nth-child(2) {
    width: 40px;
  }

  .InfoModal__DescriptionContainer {
    width: calc(100% - 120px);
  }

  .InfoModal__Header {
    height: 30px;
    padding: 0px 2px 8px 0px;
  }
  
  .InfoModal__Header img {
    height: 30px;
    margin-right: 15px;
  }
  
  .InfoModal__Header h2 {
    font-size: 1em;
  }
  
  .InfoModal__Description p {
    margin: 5px 0;
    font-size: .8em;
  }

  .InfoModal__ButtonContainer {
    height: 40px;
  }

}