.ReCaptchaModal__Overlay {
  background: #0008;
}

.ReCaptchaModal__Container {
  width: 320px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding-left: 11px;
}

@media (max-width: 450px) {

  .ReCaptchaModal__Container {
    transform: scale(.9,.9);
  }

}