@import url('../../utils/color-palatte.css');

.overlay {
  background-color: #0004;
  padding: 10px;
  box-sizing: border-box;
  backdrop-filter: blur(2px);
}

.modal {
  display: flex;
  justify-content: center;
  color: var(--WHITE_MAIN);
  background-color: var(--FEDERAL_BLUE);
  align-items: center;
  max-width: 500px;
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  flex-flow: column nowrap;
}

.Close__Btn {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 10px;
  margin-right: 10px;
  width: 25px;
  cursor: pointer;
  -webkit-tap-highlight-color: #0000;
}

.Modal__Paragraph {
  font-size: 1.2rem;
  text-align: center;
  width: calc(100% - 20px);
}

.Modal__Button {
  appearance: none;
  -webkit-appearance: none;
  background-color: var(--FEDERAL_BLUE_SOFT);
  color: var(--WHITE_MAIN);
  border: none;
  border-radius: 8px;
  height: 40px;
  width: 200px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 10px 0;
  cursor: pointer;
  transition: all .2s;
}

.Modal__Button:hover {
  background-color: var(--WARNING_YELLOW);
  color: var(--UNSATURATED_BLACK_BLUE);
}

.Info__Container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.Citizen__Img {
  width: 120px;
  margin: 0 10px;
}

.Modal__Title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 3px solid #fff;
}

.Modal__Title h1 {
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 2rem;
}

.Modal__Title img {
  width: 35px;
  margin-right: 10px;
}

.Modal__Content {
  width: calc(100% - 120px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
}

@media (max-width: 450px) {

  .Modal__Paragraph {
    font-size: 1rem;
    word-wrap: break-word;
  }

  .Modal__Button {
    width: 160px;
    font-size: 1rem;
  }
  
  .Citizen__Img {
    width: 90px;
    margin: 0 5px;
  }

  .Modal__Title h1 {
    font-size: 1.8rem;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  
  .Modal__Title img {
    width: 25px;
    margin-right: 5px;
    margin-bottom: -5px;
  }

}

