@import '../../../utils/color-palatte.css';

.Roadmap-container {
  background-color: var(--WHITE_MAIN);
}

.Roadmap-container h2 {
  font-family: 'Source Sans Pro';
  color: var(--FEDERAL_BLUE_STRONG);
  font-size: 2em;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 8px;
  margin: 0;
  margin-bottom: 50px;
}

/* .Roadmap-container h2::before {
  position: absolute;
  content: '';
  width: 300px;
  height: 2px;
  background-color: var(--FEDERAL_BLUE_STRONG);
  bottom: 0px;
} */

.Roadmap-desktop__container {
  width: 80%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  box-sizing: border-box;
}

.Roadmap-desktop {
  width: 100%;
  /* height: 400px; */
}

.Roadmap-mobile__container {
  width: 80%;
  max-width: 200px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  margin: 0 auto;
}

.Roadmap-mobile {
  width: 100%;
  /* max-width: 200px; */
  /* height: 600px; */
}

.RoadMap__desktop_run,
.RoadMap__mobile_run {
  position: absolute;
  width: 80px;
}
.RoadMap__mobile_run {
  width: 100px;
}

.Roadmap-item-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 200px;
  height: 200px;
  position: absolute;
  cursor: pointer;
  /* padding: 20px; */
}

.Roadmap-item-number-container {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: solid var(--FEDERAL_BLUE) 4px; */
  border-radius: 20px;
  position: relative;
}

.Roadmap-item-number {
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  font-family: 'Source Sans Pro';
  color: var(--FEDERAL_BLUE);
  font-size: 25px;
  margin: 0;
}

.Roadmap-item-date {
  font-weight: bold;
  font-family: 'Source Sans Pro';
  color: var(--FEDERAL_BLACK_LIGHT);
  font-size: 1.4rem;
  margin: 0;
}

.Roadmap-item-description {
  font-family: 'Source Sans Pro';
  color: var(--FEDERAL_BLUE);
  font-size: 1rem;
  margin: 0;
  text-align: initial;
}

@media (max-width: 400px) {
  .Roadmap-mobile__container {
    width: 30%;
  }
  .Roadmap-mobile {
    width: 100%;
  }
  .RoadMap__mobile_run {
    width: 60px;
  }
  .Roadmap-item-container {
    width: 100px !important;
  }
}
@media (max-width: 900px) {
  .Roadmap-item-container {
    width: 150px;
  }
  .Roadmap-item-description {
    font-size: 15px;
  }
  .Roadmap-item-date {
    font-size: 15px;
    margin: 0;
  }
}
