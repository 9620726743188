@import '../../../utils/color-palatte.css';

.FaqItem__Header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;
  height: 65px;
  cursor: pointer;
}

.FaqItem__Header p {
  font-family: 'Source Sans pro';
  font-size: 1.4em;
  letter-spacing: -1px;
}

.FaqItem__Arrow {
  transition: all .3s;
  cursor: pointer;
  width: 18px;
}

.FaqItem__Container {
  width: 100%;
  transition: height .4s ease-in-out;
  box-sizing: border-box;
  overflow: hidden;
}

@media (max-width: 590px) {
 
  .FaqItem__Title {
    width: calc(100% - 50px);
    text-align: center;
  }
}

@media (max-width: 400px) {
  .FaqItem__Header {
    height: 85px;
  }
}