@import url('../../utils/color-palatte.css');

.CreatePost__Container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}

.CreatePost__Content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 700px;
}

.CreatePost__Title {
  width: 100%;
  text-align: center;
  color: var(--FEDERAL_BLUE);
}

.CreatePost__ModalItemContainer{
  width: 100%;
  display: flex;
  flex-flow: column;
}

.CreatePost__SelectImg-Container {
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.CreatePost__SelectImg-warning {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  opacity: .8;
  pointer-events: none;
}

.CreatePost__DeleteIcon {
  position: absolute;
  top: 0;
  right: 0;
  margin: 3px;
  height: 25px;
  z-index: 1;
  cursor: pointer;
}

.CreatePost__Select-counter {
  position: absolute;
  top: 0;
  left: 0;
  margin: 5px;
  background: white;
  color: var(--FEDERAL_BLUE_STRONG);
  font-size: .8rem;
  min-width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: calc(16px / 2);
  box-sizing: border-box;
  padding-bottom: 2px;
}

.CreatePost__Buttons-area {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column nowrap;
}

.CreatePost__Buttons-content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
}

.CreatePost__Button-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
}

.CreatePost__Button-container p {
  color: var(--FEDERAL_BLUE);
  font-size: 1.2rem;
  margin: 0;
}

.CreatePost__Button {
  width: 60px;
  height: 60px;
  background-color: var(--FEDERAL_BLUE);
  margin: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: calc(60px / 2);
  border: none;
  position: relative;
  transition: all .2s;
  cursor: pointer;
}

.CreatePost__Button input[type='file']{
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 60px !important;
  height: 60px !important;
}
.CreatePost__Button:hover {
  background-color: var(--FEDERAL_BLUE_SOFT);
}

.CreatePost__Button img {
  width: 35px;
}

.CreatePost__Plus-logo {
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -3px;
  margin-right: -3px;
  border: 2px solid white;
  box-shadow: 2px 2px 3px 0 #0004;
  background-color: var(--FEDERAL_BLUE_STRONG);
  border-radius: calc(24px / 2);
}

.CreatePost__Plus-logo img {
  width: 16px;
  height: 16px;
}

.CreatePost__TextArea {
  margin-top: 20px;
}

.CreatePost__Create {
  margin-top: 10px;
  width: 100%;
  height: 40px;
  background: var(--FEDERAL_BLUE);
  color: #fff;
  font-size: 1.2rem;
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 10px;
  border: none;
}

@media (max-width: 645px) {
  
  .CreatePost__SelectImg-Container {
    position: relative;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
  }

}