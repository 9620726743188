.Search__btn {
  width: 50px;
  height: 50px;
  background-color: #fff;
  border: none;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 2px;
  cursor: pointer;
  transition: all 0.3s;
}

.Search__Container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
}

.Search__Search-bar {
  width: calc(100% - 80px);
  max-width: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  margin-top: 15px;
  margin-bottom: 5px;
}

.Search__Icon {
  margin-left: 15px;
  width: 25px;
  cursor: pointer;
}

.Search__btn:hover {
  background-color: #4AA7E5;
}

.Search__Filter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  box-sizing: border-box;
}

.Search__FiltBtn {
  margin: 10px;
  margin-top: 0;
  text-decoration: none;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
}

.Search__FilterButton {
  width: 95px;
  border: none;
  height: 35px;
  border-radius: 5px;
  margin: 5px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1rem;
  cursor: pointer;
}

.Search__FiltDate {
  display: flex;
  width: 100%;
  /* justify-content: space-evenly; */
  align-items: center;
  /* flex-flow: row nowrap; */
  max-width: 420px;
  flex-direction: column;
}

.Search__Date-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
}

.Search__DateText {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
}
.Search__DateText p {
  margin: 0;
}

.Search__DateImg {
  width: 25px;
  margin-left: 10px;
}

@media (max-width: 500px) {

  .Search__FilterButton {
    /* max-width: 95px; */
    width: 25%;
    border: none;
    height: 35px;
    border-radius: 5px;
    margin: 5px;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 0.7rem;
  }
}
@media (max-width: 600px) {
  .Search__Search-bar {
    width: calc(100% - 40px);
  }
}