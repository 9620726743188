.footer {
  font-family: 'Source Sans Pro', sans-serif;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  background-color: #2573B4;
  color: white;
  min-height: 300px;
  padding: 60px;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.footer__url {
  text-decoration: none;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.4em;
  margin: 5px 0;
}

.footer__content1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 20px;
}

.footer__url img {
  width: 25px;
  margin-right: 10px;
}

.footer__url p {
  margin: 0;
}

.footer__Citizen {
  position: relative;
  width: 160px;
  height: 318px;
  user-select: none;
}

.footer__BGImg {
  width: 100%;
  margin-top: 25px;
}

.footer__Img-Menu {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: inherit;
}

.footer__Img-Menu img {
  cursor: pointer;
}

.Footer__Flag > .flag-select__btn:after {
  border-top: 5px solid #fff !important;
  border-left: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
  border-bottom: 0 !important;
}
.Footer__Flag > .flag-select__btn[aria-expanded='true']:after {
  border-top: 0 !important;
  border-left: 5px solid transparent !important;
  border-right: 5px solid transparent !important;
  border-bottom: 5px solid #fff !important;
}
.Footer__Flag > .flag-select__btn:before {
  content: ' ';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
}

@media (max-width: 1115px) {

  .footer {
    justify-content: space-between;
  }

}

@media (max-width: 775px) {

  .footer {
    justify-content: flex-start;
    flex-direction: column;
  }

}

@media (max-width: 480px) {

  .footer {
    padding: 30px;
  }
  
}

@media (max-width: 420px) {

  .footer__url {
    font-size: 1em;
    word-wrap: break-word;
    white-space: normal;
  }
  
}