@import url('../../utils/color-palatte.css');

.Service__Container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  box-sizing: border-box;
  padding: 10px
}

.Service__Search {
  width: 100%;
  max-width: calc(100% - 250px);
}

.Service__NFI {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: column nowrap;
  position: relative;
  height: calc(100vh - 150px);
  width: 100%;
}

@media (max-width: 600px) {
  .Service__Search {
    max-width: 100%;
  }
}