@import url('../../utils/color-palatte.css');

.TermsModal__Overlay {
  background-color: #0008;
  backdrop-filter: blur(2px);
  padding: 10px;
  box-sizing: border-box;
}

.TermsModal__Container {
  width: 650px;
  padding: 5px 15px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  box-shadow: 0px 3px 8px 2px #0008;
}

.TermsModal__Header {
  width: 100%;
  border-bottom: 1px solid var(--WHITE_DARK);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}

.TermsModal__Close-btn {
  position: absolute;
  width: 25px;
  top: 10px;
  right: 5px;
  cursor: pointer;
}

.TermsModal__Header h1 {
  color: var(--FEDERAL_BLACK_LIGHT);
  margin: 10px;
  margin-top: 20px;
  width: 100%;
  text-align: center;
}

.Terms__Scroll-Container {
  width: 100%;
  height: 300px;
  position: relative;
}

.TermsModal__Scroll-area {
  height: inherit;
  overflow: scroll;
}
.TermsModal__Scroll-area::-webkit-scrollbar-thumb {
  background: #0005;
}

.TermsModal__Vanish {
  width: calc(100% - 10px);
  height: 60px;
  position: absolute;
  background: linear-gradient(0deg, #0000, #FFF);
  transition: all 1s;
  left: 0;
}

.TermsModal__VSH1 {
  top: 0;
}

.TermsModal__VSH2 {
  bottom: 0;
  transform: rotateX(180deg);
}

.TermsModal__Footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  min-height: 130px;
}

.TermsModal__Check-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TermsModal__CheckBox {
  margin-right: 5px;
}

.TermsModal__Button {
  width: 140px;
  height: 45px;
  border: none;
  border-radius: 8px;
  margin-bottom: 20px;
  margin-top: 10px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.2em;
}

@media (max-width: 500px) {

  .TermsModal__Header h1 {
    font-size: 1.3em;
  }

  .TermsModal__Close-btn {
    width: 15px;
  }

  .TermsModal__Footer p {
    font-size: .8em;
  }

}