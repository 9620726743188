@import url('../../utils/color-palatte.css');

.PostComments__Container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 0 10px;
  min-height: 85vh;
}

@media (max-width: 450px) {
  .PostComments__Container {
    padding: 0;
  }
}