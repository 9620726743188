.PositionItem__Container {
  width: 100%;
  min-height: 150px;
  margin: 10px 0;
  max-width: 500px;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 15px;
  position: relative;
}

.PositionItem__Header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding-bottom: 10px;
}

.PositionItem__PictureContainer {
  margin-right: 10px;
}

.PositionItem__UserPosition {
  margin: 5px 0;
  font-weight: bold;
  text-align: center;
  font-size: .9rem;
  min-width: 30;
  height: 16;
  width: fit-content;
  padding: 0 8px;
  border-radius: calc(16px / 2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.PositionItem__Status {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
  margin: 10px 12px 0 0;
}