@import url('../../utils/color-palatte.css');

.NavBar__container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  width: 100%;
}

.NavBar__button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row;
  margin-bottom: 10px;
  height: 50px;
  position: relative;
  transition: all 0.3s;
}
.NavBar__button span {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  transition: all 0.3s;
}
.NavBar__button:hover span {
  width: 100%;
}

.NavBar__button p {
  color: var(--FEDERAL_BLUE);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.3em;
  margin: 0;
}

.NavBar__button img {
  width: 30px;
  margin-right: 8px;
}

.NavBar__menu-container {
  width: 300px;
  max-width: 300px;
  min-height: 100vh;
  height: 100%;
  top: 0;
  left: 0;
  position: sticky;
}

.NavBar__menu {
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  padding: 35px;
  padding-top: 100px;
}
.NavBar__menu::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.NavBar__menu::-webkit-scrollbar-thumb {
  background-color: var(--FEDERAL_BLUE_STRONG);
  opacity: 0.5;
}

.NavBar__content {
  width: calc(100% - 300px);
  box-sizing: border-box;
  padding-top: 70px;
}

@media (max-width: 800px) {
  .NavBar__container {
    background-size: 1000px;
  }
}

@media (max-width: 650px) {
  .NavBar__content {
    padding-top: 50px;
  }
}

/* prueba */
