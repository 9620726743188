@import '../color-palatte.css';

.GeneralInput__Container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 5px 20px;
  box-sizing: border-box;
}

.GeneralInput {
  width: 100%;
  height: 50px;
  color: var(--FEDERAL_BLUE_SOFT);
  background-color: transparent;
  resize: none;
  border-radius: 8px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em;
  outline: none;
  padding: 10px;
  box-sizing: border-box;
}

.GeneralInput::placeholder {
  color: var(--FEDERAL_BLUE_SOFT);
}

.GeneralInputDark {
  width: 100%;
  height: 50px;
  color: #FFF;
  background-color: transparent;
  resize: none;
  border-radius: 8px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em;
  outline: none;
  padding: 10px;
  box-sizing: border-box;
}

.GeneralInputDark::placeholder {
  color: #FFF;
}

.GeneralInputErrors::placeholder {
  color: var(--FEDERAL_BLUE_SOFT);
}
