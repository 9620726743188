.ListItem__Li {
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 20px;
  color: #FFF;
  margin: 5px 0;
}

.ListItem__Li img {
  width: 25px;
  height: 25px;
  padding: 0 10px;
}

.ListItem__Li p {
  margin: 0;
} 