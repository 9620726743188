@import url('../../utils/color-palatte.css');

.GG__Container {
  width: 100%;
  max-width: 800px;
  display: flex;
  margin: 0 auto;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  position: relative;
}

.GG__Banner {
  width: 100%;
  height: 200px;
  background-image: url('../../assets/global-government/GGBanner.png');
  background-repeat: no-repeat;
  /* object-fit: contain; */
  background-size: cover;
  position: relative;
}

.GG__Content {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
}

.GG__BKOrnament {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: scale(.8,.8);
}

.GG__ProfBtn {
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  margin-top: 15px; 
}

.GG__Data-Container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-flow: column nowrap;
}

.GG__Data-buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: row nowrap;
}

.GG__SideBtn {
  background: none;
  border: none;
  display: flex;
  justify-content: flex-start;
  flex-flow: column nowrap;
  box-sizing: border-box;
  padding: 8px;
  transition: all .2px;
  cursor: pointer;
}

.GG__SideBtn:hover {
  background: #5558;
}

.GG__SideBtn1 {
  align-items: flex-start;
  border-radius: 0 0 10px 0;
}
.GG__SideBtn2 {
  align-items: flex-end;
  border-radius: 0 0 0 10px;
}

.GG__SideBtn p {
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 8px;
}

.GG__SideBtn img {
  height: 45px;
}

.GG__Positions {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
}

.GG__PosotionOrnament {
  transform: scale(.8,.8);
}

.GG__ImportantText {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  min-width: 200px;
  min-height: 100px;
  background-color: var(--FEDERAL_BLUE);
  padding: 15px;
  border-radius: 15px;
  margin: 20px;
}

.GG__ImportantText h3 {
  margin: 0;
  background-color: white;
  padding: 5px;
  width: 150px;
  text-align: center;
  border-radius: 10px;
  color: var(--FEDERAL_BLUE);
}

.GG__ImportantText p {
  color: white;
}

@media ( max-width: 600px ) {

  .GG__BKOrnament {
    transform: scale(.5,.5);
  }


  .GG__SideBtn p {
    font-size: 0.8rem;
  }

  .GG__PosotionOrnament {
    transform: scale(.4,.4);
    width: 100px;
    margin: -50px;
    transform-origin: center;
  }

  .GG__ProfBtn {
    width: 100%;
  }

  .GG__Positions {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;
  }
}