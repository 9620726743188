@import url('../../utils/color-palatte.css');


.CreateVote__Container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
}

.CreateVote__Form {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  width: 100%;
  max-width: 800px;
}

.CreateVote__H1 {
  color: var(--FEDERAL_WHITE);
  text-align: center;
}

.CreateVote__Button {
  width: 100%;
  border-radius: 5px;
  border: none;
  background-color: var(--FEDERAL_BLUE);
  color: white;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  font-size: 1.2em;
  height: 40px;
  margin-top: 10px;
  cursor: pointer;
}

.CreateVote__Title {
  margin: 10px;
}