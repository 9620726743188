@import url('../../../utils/color-palatte.css');

.PaymentRenovDone__Overlay {
  background: #0008;
  box-sizing: border-box;
  padding: 5px;
}

.PaymentRenovDone__Container {
  background-color: var(--FEDERAL_BLUE);
  width: 100%;
  max-width: 750px;
  min-height: 300px;
  border-radius: 10px;
  max-height: calc(100vh - 20px);
  overflow: auto;
}

.PaymentRenovDone__Container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.PaymentRenovDone__Header {
  width: 100%;
  height: 170px;
  display: flex;
  text-align: center;
  border-radius: 10px 10px 0 0;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  flex-flow: column nowrap;
  padding: 70px 10px 30px 10px;
  position: relative;
  background: #000;
}

.PaymentRenovDone__Header::before {
  content: '';
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
  text-align: center;
  border-radius: 10px 10px 0 0;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  flex-flow: column nowrap;
  padding: 70px 10px 30px 10px;
  background-color: var(--FEDERAL_BLUE_SOFT);
  background-image: url(../../../assets/payment-modals/paymentRenovDoneBg.png);
  background-position: 50% 38%;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 0;
  opacity: .7;
}

.PaymentRenovDone__Header h1 {
  display: flex;
  justify-content: center;
  text-align: center;
  color: #fff;
  text-shadow: -2px 2px 3px #0008;
  margin: -5px;
  z-index: 1;
  position: relative;
}

.PaymentRenovDone__Header h1::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background: linear-gradient(
    90deg,
    transparent,
    var(--FEDERAL_WHITE),
    transparent
  );
  top: unset;
  left: 0;
  bottom: 0;
  margin-bottom: -5px;
}

.PaymentRenovDone__Header h3 {
  display: flex;
  justify-content: center;
  text-align: center;
  color: var(--WARNING_YELLOW);
  text-shadow: -2px 2px 3px #0008;
  z-index: 1;
}

.PaymentRenovDone__Content {
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 10px;
}

.PaymentRenovDone__Content h3 {
  display: flex;
  justify-content: center;
  text-align: center;
  color: #fff;
  margin: 10px;
  position: relative;
  margin-bottom: 25px;
}

.PaymentRenovDone__Content h3::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background: linear-gradient(
    90deg,
    transparent,
    var(--FEDERAL_WHITE),
    transparent
  );
  left: 0;
  bottom: 0;
  margin-bottom: -10px;
}

.PaymentRenovDone__btnCont button {
  width: 120px;
  background: var(--WARNING_YELLOW);
  color: var(--FEDERAL_BLUE);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 20px auto;
  border: none;
  border-radius: 5px;
  padding: 7px 25px;
  text-decoration: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1rem;
  transition: all 0.3s;
  cursor: pointer
}

.PaymentRenovDone__btnCont button:hover {
  background: var(--FEDERAL_LOW_BLUE);
}
@media (max-width: 600px) {

  .PaymentRenovDone__Header h1 {
    font-size: 1.5rem;
  }
}

 
