@import url('../../utils/color-palatte.css');

.PostDetails__Container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 0 10px;
}

@media (max-width: 450px) {
  .PostDetails__Container {
    padding: 0;
  }
}