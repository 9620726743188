@import url('../../utils/color-palatte.css');

.MessageItem__Container {
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  padding: 10px;
  box-sizing: border-box;
}

.MessageItem__Item {
  padding: 8px;
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
}

.MessageItem__Header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row nowrap;
}

.MessageItem__UserImage-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.MessageItem__Title {
  width: 100%;
  box-sizing: border-box;
}

.MessageItem__Item[datainner='true']:active {
  background-color: var(--FEDERAL_BLUE_SOFT);
}

