@import url('../../utils/color-palatte.css');

.Finance__Container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  box-sizing: border-box;
  max-width: 800px;
  margin: auto;
}

.Finance__Banner {
  width: 100%;
  position: relative;
  padding-top: 33%;
  background-image: url('../../assets/finances/finance_banner.svg');
  background-size: calc(100% + 30px);
  background-repeat: no-repeat;
  background-position: top center;
}

.Finances__Ornament-container {
  position: absolute;
  bottom: 0;
  height: 240px;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  margin-bottom: -120px;
}

.Finance__Content {
  display: flex;
  margin-top: 150px;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 10px;
}

.Finance__Bubble1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 620px;
  flex-flow: column nowrap;
  padding: 10px;
  box-sizing: border-box;
}

.Finance__Total-Item {
  background-color: var(--FEDERAL_BLUE);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 10px;
  color: #FFF;
  box-sizing: border-box;
  padding: 10px;
  height: 60px;
  border-radius: 10px;
}

.Finance__Total-Item p {
  font-size: 1.3rem;
}

.Finance__Total-Item img {
  width: 35px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  margin-right: 20px;
  cursor: pointer;
  padding: 3px;
  box-sizing: border-box;
  border-radius: 50%;
  transition: all .2s;
}

.Finance__Total-Item2 {
  background-color: var(--FEDERAL_BLUE);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 10px;
  color: #FFF;
  box-sizing: border-box;
  padding: 10px;
  height: 60px;
  border-radius: 10px;
}

.Finance__Total-Item2 p {
  font-size: 1.3rem;
}

.Finance__Total-Item2 img {
  position: absolute;
  right: 0;
  padding-right: 20px;
  cursor: pointer;
}

.Finance__Total-Item img:hover {
  background-color: #FFF5;
}

.Finance__Balance-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  width: 100%;
  margin: 10px 0;
}

.FinanceItem__Container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-flow: column nowrap;
  color: #FFF;
  background-color: var(--FEDERAL_BLUE);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
}

.FinanceItemTitle__Container h2 {
  width: 100%;
  text-align: center;
  margin: 10px 0;
}

.FinanceItemTitle__Container {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #FFF;
}

.FinanceItemTitle__Container img {
  width: 35px;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  padding: 3px;
  box-sizing: border-box;
  border-radius: 50%;
  transition: all .2s;
}

.FinanceItemTitle__Container img:hover {
  background-color: #FFF5;
}

.FinanceItem__Content {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
}

.FinanceItem__Content div {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  margin: 20px 0;
}

.FinanceItem__Content div img {
  margin-right: 20px;
  width: 30px;
}

.FinanceItem__Content div p {
  font-size: 1.3rem;
  font-weight: bold;
  margin: 0;
}

.FinanceItem__Content button {
  border: none;
  background-color: #FFF;
  color: var(--FEDERAL_BLUE);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1rem;
  height: 40px;
  width: 100%;
  max-width: 180px;
  border-radius: 10px;
  cursor: pointer;
  transition: all .3s;
  -webkit-tap-highlight-color: #0000;
}

.FinanceItem__Content button:hover {
  border: none;
  background-color: var(--WARNING_YELLOW);
  color: var(--FEDERAL_BLUE);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1rem;
  height: 40px;
  width: 100%;
  max-width: 180px;
  border-radius: 10px;
  cursor: pointer;
  transition: all .2s;
  -webkit-tap-highlight-color: #0000;
}