@import url('../../color-palatte.css');

.CustomTextInput-Container {
  width: 100%;
  /* padding: 5px 0px; */
  box-sizing: border-box;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CustomTextInput-Container > div:first-child {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  min-height: 40px;
}
.CustomTextInput-Container textarea {
  border: none !important;
  box-sizing: border-box;
  padding: 5px 10px;
  color: var(--FEDERAL_BLUE) !important;
  -webkit-text-fill-color: var(--FEDERAL_BLUE) !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 0.9em !important;
  border-radius: 12px !important;
}

.CustomTextInput-Container textarea::placeholder {
  color: var(--FEDERAL_BLUE);
  /* For Firefox Browser */
  opacity: 1;
}
.CustomTextInput-Container input {
  border: none !important;
  box-sizing: border-box;
  padding: 10px !important;
  color: var(--FEDERAL_BLUE) !important;
  -webkit-text-fill-color: var(--FEDERAL_BLUE) !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em !important;
  border-radius: 12px !important;
}

.CustomTextInput-Container .example_mentions__1glIn input::placeholder {
  color: var(--FEDERAL_BLUE);
  /* For Firefox Browser */
  opacity: 1;
}

.CustomTextInput-Container-white {
  width: 100%;
  /* padding: 5px 0px; */
  box-sizing: border-box;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CustomTextInput-Container-white > div:first-child {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  min-height: 40px;
}
.CustomTextInput-Container-white textarea {
  border: none !important;
  box-sizing: border-box;
  padding: 5px 10px;
  color: #FFF !important;
  -webkit-text-fill-color: #FFF !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 0.9em !important;
  border-radius: 12px !important;
}

.CustomTextInput-Container-white textarea::placeholder {
  color: #FFF;
  /* For Firefox Browser */
  opacity: 1;
}
.CustomTextInput-Container-white input {
  border: none !important;
  box-sizing: border-box;
  padding: 10px !important;
  color: #FFF !important;
  -webkit-text-fill-color: #FFF !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em !important;
  border-radius: 12px !important;
}

.CustomTextInput-Container-white .example_mentions__1glIn input::placeholder {
  color: #FFF;
  /* For Firefox Browser */
  opacity: 1;
}

.CustomTextInput {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 12px;
  color: var(--FEDERAL_BLUE);
  background-color: transparent;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em;
}

.CustomTextInput:-webkit-autofill,
.CustomTextInput:-webkit-autofill:hover,
.CustomTextInput:-webkit-autofill:focus,
.CustomTextInput:-internal-autofill-selected,
.CustomTextInput:-internal-autofill-selected:hover,
.CustomTextInput:-internal-autofill-selected:focus {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 12px;
  color: var(--FEDERAL_BLUE) !important;
  -webkit-text-fill-color: var(--FEDERAL_BLUE) !important;
  background-color: #E6E6E6;
  box-shadow: 0 0 0 30px #E6E6E6 inset !important;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em !important;
}

.CustomTextInput::placeholder {
  color: var(--FEDERAL_BLUE);
  /* For Firefox Browser */
  opacity: 1;
}

.CustomTextInput-white {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 12px;
  color: white;
  background-color: transparent;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em;
}

.CustomTextInput-white:-webkit-autofill,
.CustomTextInput-white:-webkit-autofill:hover,
.CustomTextInput-white:-webkit-autofill:focus,
.CustomTextInput-white:-internal-autofill-selected,
.CustomTextInput-white:-internal-autofill-selected:hover,
.CustomTextInput-white:-internal-autofill-selected:focus {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 12px;
  color: #FFF !important;
  -webkit-text-fill-color: #FFF !important;
  background-color: var(--FEDERAL_BLUE) !important;
  box-shadow: 0 0 0 30px var(--FEDERAL_BLUE) inset !important;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-size: 1.1em !important;
  caret-color: #FFF !important;
}

.CustomTextInput-white::placeholder {
  color: white;
  opacity: 1;
}
