@import url('../../../utils/color-palatte.css');

.EditCommunity__Overlay {
  background-color: #0004;
  box-sizing: border-box;
  padding: 5px;
}

.EditCommunity__Container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 800px;
  min-height: 300px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 12px;
  position: relative;
  max-height: calc(100vh - 10px);
  overflow: auto;
}
.EditCommunity__Container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.EditCommunity__CloseImg {
  position: absolute;
  right: 0;
  top: 0;
  width: 25px;
  margin-top: 15px;
  margin-right: 15px;
  cursor: pointer;
}

.EditCommunity__Title {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 0;
  padding-bottom: 5px;
  box-sizing: border-box;
  text-align: center;
}

.EditCommunity__Content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  box-sizing: border-box;
  padding: 10px 15px;
}

.EditCommunity__PictureContainer {
  width: 100px;
  margin-right: 15px;
  position: relative;
}

.EditCommunity__Icon {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  margin-top: 25px;
  width: 50px;
  opacity: .7;
  cursor: pointer;
  z-index: 1;
}

.EditCommunity__Data {
  width: calc(100% - 110px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column nowrap;
  align-items: center;
}

.EditCommunity__Data p {
  margin: 0;
  font-size: 1.1rem;
}

.EditCommunity__Button {
  -webkit-tap-highlight-color: #0000;
}

.EditCommunity__Delete-btn {
  padding: 0 10px;
  width: 125px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  color: #fff;
  background-color: var(--FEDERAL_BLUE);
  font-family: 'Source Sans Pro', sans-serif;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.EditCommunity__Delete-btn:hover {
  background-color: var(--FEDERAL_BLUE_SOFT);
}

@media (max-width: 600px) {

  .EditCommunity__PictureContainer {
    margin-right: unset;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: column nowrap;
    width: 100%;
  }

  .EditCommunity__Data {
    width: 100%;
  }

}