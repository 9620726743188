@import '../../utils/color-palatte.css';

.Faq__Banner {
  width: 100%;
  /* min-height: 300px; */
  /* background: url(../../assets/faq/Faq_Banner.svg); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  background-image: url('../../assets/log-in/login-bg.png');
}

.Faq__Banner p {
  color: var(--WHITE_MAIN);
  /* margin-top: 50px; */
  font-weight: 500;
}

.Faq__Banner-title {
  font-family: 'Source Sans Pro';
  font-weight: 500;
  color: var(--FEDERAL_BLUE);
  font-size: 1.8em;
  text-align: center;
}

.Faq__Banner-title span {
  font-weight: 700;
  text-transform: uppercase;
}

.Faq__SeachBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 500px;
  border: 2px solid var(--FEDERAL_BLUE);
  height: 40px;
  border-radius: 30px;
  background: var(--FEDERAL_LIGHT_BLUE);
}

.Faq__SeachBox button {
  background: none;
  border: none;
  width: 28px;
  margin-left: 5px;
  margin-right: 5px;
}

.Faq__SeachBox button img {
  width: inherit;
}

.Faq__SeachBox input {
  background: none;
  border: none;
  width: 80%;
  border-radius: 0;
  color: var(--FEDERAL_BLUE);
  font-family: 'Source Sans Pro';
  margin-left: 5px;
  font-size: 1.1em;
  margin-bottom: 0;
}

.Faq__SeachBox input::placeholder {
  color: var(--FEDERAL_BLUE);
  opacity: 1;
}

.Faq__Questions {
  width: 100%;
  /* margin-top: 100px */
}

.Faq__Header {
  width: inherit;
  height: 70px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 50px;
}

.Faq__Header h1 {
  margin: 0;
}

@media (max-width: 590px) {
  
  .Faq__SeachBox {
    width: 80%;
    overflow: hidden;
  }
  /* .Faq__Banner p {
    width: 90%;
    text-align: center;
  } */

}