@import url('../../utils/color-palatte.css');

.PostCard__Container {
  position: relative;
  max-width: 800px;
  width: 100%;
  box-sizing: border-box;
  background-clip: padding-box;
  padding: 20px 35px;
  margin: 10px auto;
  border-radius: 10px;
}

.PostCard__Container:first-child {
  margin-top: 20px;
}

.PostCard__Container:last-child {
  margin-bottom: 20px;
}

.PostCard__Header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 60px;
  margin-bottom: 20px;
}

.PostCard__User-Image-container {
  width: 60px;
  height: 60px;
  border: 3px solid var(--FEDERAL_BLUE);
  border-radius: calc(60px / 2);
  box-sizing: border-box;
}

.PostCard__UserImg {
  width: 100%;
  border-radius: inherit;
  cursor: pointer;
}

.PostCard__Title {
  width: calc(100% - 60px);
  padding-left: 20px;
  box-sizing: border-box;
}

.PostCard__User-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 30px;
}

.PostCard__Ornament-name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row;
}

.PostCard__Username {
  margin: 0;
  font-size: 1.8em;
}

.PostCard__User-PostDate {
  margin: 0;
  font-size: 1em;
  color: var(--GRAY_MAIN);
  text-align: left;
  margin-top: 5px;
}

.PostCard__options-btn {
  position: absolute;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: calc(30px / 2);
  box-sizing: border-box;
  padding: 5px;
  transition: all 0.2s;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.PostCard__options-btn:hover {
  background: #0004;
}

.PostCard__Title-Container {
  width: 100%;
  margin-bottom: 20px;
}

.PostCard__Title-text {
  margin: 0;
}

.PostCard__Content {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  /* height: 550px; */
  margin-bottom: 10px;
}

.PostCard__Carousel > .rec > .rec-slider-container {
  margin: 0 !important;
}

.PostCard__Carousel > .rec-carousel {
  border-radius: 20px !important;
  overflow: hidden;
  border: 1px solid #aaa;
}

.PostCard__Carousel-dark > .rec > .rec-slider-container {
  margin: 0 !important;
}

.PostCard__Carousel-dark > .rec-carousel {
  border-radius: 20px !important;
  overflow: hidden;
  border: 1px solid #3a8efc6c;
}

.PostCard__CarouselItem {
  width: 100%;
  /* height: 500px; */
  padding-top: calc(500 / 730 * 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.PostCard__Main-Image {
  position: absolute;
  top: 50%;
  left: 50%;
  height: auto;
  width: auto;
  max-height: 500px;
  max-width: 100%;
  transform: translate(-50%, -50%);
}

.PostCard__ReplaceImage {
  position: absolute;
  top: 50%;
  left: 50%;
  height: auto;
  width: auto;
  max-height: 500px;
  max-width: 100%;
  transform: translate(-50%, -50%);
}

.PostCard__Main-Video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.PostCard__Lock-btn {
  position: absolute;
  right: 0;
  top: 0;
  opacity: .2;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  border: 2px solid black;
  margin: 15px;
}

.PostCard__Lock-btn:hover {
  opacity: .5;
}

.PostCard__Lock-btn img {
  width: 100%;
}

.PostCard__Carousel-flex {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-flow: row;
  height: 40px;
  box-sizing: border-box;
  padding: 0 10px;
}

.PostCard__Carousel-dot {
  transition: all 0.2s;
  width: 5px;
  height: 5px;
  background: var(--FEDERAL_BLUE);
  opacity: 0.5;
  border-radius: 3px;
  margin: 5px;
}

.PostCard__Carousel-dot:hover {
  opacity: 0.7;
  transform: scale(1.2, 1.2);
  cursor: pointer;
}

.PostCard__Like-area {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.PostCard__Buttons-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row;
  max-width: 400px;
}

.PostCard__CommentArea {
  margin-top: 15px;
  box-sizing: border-box;
  padding-top: 10px;
}

.PostCard__Overlay-warning {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(20px);
}

.PostCard__Overlay-warning-modal {
  display: flex;
  width: calc(100% - 20px);
  max-width: 600px;
  padding: 20px 25px;
  background-color: var(--UNSATURATED_BLACK_BLUE);
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  border-radius: 10px;
}

.PostCard__Overlay-warning-modal > button {
  margin: 20px 0 15px 0;
  padding: 5px 60px;
  background-color: var(--FEDERAL_BLUE);
  color: var(--WHITE_MAIN);
  border: none;
  border-radius: 8px;
  font-weight: bold;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.3em;
  cursor: pointer;
  transition: all .3s;
}
.PostCard__Overlay-warning-modal > button:hover {
  background-color: var(--FEDERAL_BLUE_SOFT);
}

@media (max-width: 600px) {
  
  .PostCard__Container {
    padding: 12px;
  }

  .PostCard__Header {
    min-height: 30px;
    margin-bottom: 10px;
  }

  .PostCard__Title-Container {
    margin-bottom: 10px;
  }

  .PostCard__Title {
    width: calc(100% - 50px);
    padding-left: 10px;
  }

  .PostCard__User-Image-container {
    width: 50px;
    height: 50px;
    border: 3px solid var(--FEDERAL_BLUE);
    border-radius: calc(50px / 2);
  }

  .PostCard__Username {
    font-size: 1.3em;
  }

  .PostCard__User-PostDate {
    font-size: .8em;
  }
  
  .PostCard__Carousel > .rec-carousel {
    border-radius: 10px !important;
  }
  
  .PostCard__Carousel-dark > .rec-carousel {
    border-radius: 10px !important;
  }

  .PostCard__options-btn {
    width: 25px;
    height: 25px;
    border-radius: calc(25px / 2);
    padding: 4px;
  }
  
  .PostCard__options-btn:hover {
    background: unset;
  }
  
  .PostCard__options-btn:active {
    background: #0004;
  }

  .PostCard__Buttons-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-flow: row;
    max-width: unset;
    height: 35px;
  }

  .Post__Button-img {
    width: 22px;
  }

}

@media (max-width: 450px) {

  .PostCard__Container {
    border-radius: 0;
    margin: 5px 0;
  }

  .PostCard__Container:first-child {
    margin-top: 10px;
  }
  
  .PostCard__Container:last-child {
    margin-bottom: 10px;
  }

  .PostCard__User-Image-container {
    width: 40px;
    height: 40px;
    border: 2px solid var(--FEDERAL_BLUE);
    border-radius: calc(40px / 2);
  }

  .PostCard__Username {
    font-size: 1em;
  }

  .PostCard__User-PostDate {
    font-size: .7rem;
  }

  .PostCard__options-btn {
    width: 25px;
    height: 25px;
    border-radius: calc(25px / 2);
    padding: 5px;
  }

  .PostCard__Title {
    width: calc(100% - 40px);
    padding-left: 10px;
  }

  .PostCard__Title-text {
    font-size: .8rem;
  }

}