.refContainer {
  position: absolute;
  bottom: 50px;
  width: 95%;
  background-color: var(--DARK_OFFICIAL_BLUE);
  margin: 0 auto;
  max-width: 800px;
  left: 50%;
  transform: translate(-50%, 0);
}

.CancelRef__Button {
  width: 200px;
  border-radius: 5px;
  border: none;
  background-color: var(--FEDERAL_BLUE);
  color: white;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  font-size: 1.2em;
  height: 40px;
  margin-top: 10px;
  cursor: pointer;
  margin: auto;
}
