.Video-container {
  width: 100%;
  height: 19.3vw;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.mediaPlayer {
  width: 95vw;
  max-width: 900px;
  height: 46vw;
  max-height: 500px;
  border: 10px solid white;
  border-radius: 40px;
  background-color: #000;
  box-shadow: -2px 2px 5px 0px rgba(0,0,0,0.5);
  position: absolute;
  bottom: 0;
  margin-bottom: 10px;
  background-clip: padding-box;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 690px) {

  .Video-container {
    width: 100%;
    height: 100px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 18vw;
  }

  .mediaPlayer {
    width: calc(100% - 30px);
    background-size: cover;
    border: 6px solid white;
    border-radius: 30px;
  }
}

@media (max-width: 400px) {

  .Video-container {
    width: 100%;
    height: 100px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 18vw;
  }

  .mediaPlayer {
    width: calc(100% - 30px);
    background-size: cover;
    height: 55vw;
    border: 6px solid white;
    border-radius: 30px;
  }
}
