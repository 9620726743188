.ChatItem__Container {
  width: 90%;
  max-width: 600px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px;
  min-height: 70px;
  margin: 5px 0;
  cursor: pointer;
  position: relative;
}

.ChatItem__Content {
  width: calc(100% - 70px);
  margin-left: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  flex-flow: column nowrap;
}

.ChatItem__Header {
  position: relative;
  width: 100%;
  height: 34px;
}

.ChatItem__Footer {
  position: relative;
  width: 90%;
  height: calc(60px - 34px);
}

.ChatItem__Date {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0;
  top: 0;
  font-size: 0.7rem;
}

.ChatItem__MsgCounter {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0%, -50%);
  font-size: 1rem;
  font-weight: bold;
  background-color: var(--FEDERAL_BLUE_STRONG);
  border-radius: calc(30px / 2);
  min-width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-right: 50px;
  margin-top: 10px;
  padding: 5px 10px;
  box-sizing: border-box;
}

.LastMessage {
  /* position: absolute;
  right: 0;
  bottom: 0;
  margin: 0; */
  font-size: 1rem;
  /* font-weight: bold; */
  color: var(--FEDERAL_WHITE);
  padding: 5px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.ChatItem__Menu__Btn {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0%, -50%);
  margin-right: 10px;
  margin-top: 10px;
}