@import url('../../utils/color-palatte.css');

.Header__Container {
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
  background-color: var(--FEDERAL_BLUE);
  z-index: 9000;
  min-width: 300px;
}

.logo {
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin-right: 5px;
}

.logo2 {
  width: 49px;
  height: 50px;
  cursor: pointer;
  margin-right: 6px;
}

.Header__Title {
  font-family: 'Source Sans Pro';
}

.title {
  cursor: 'pointer';
}

.header__buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  height: inherit;
  box-sizing: border-box;
  padding: 10px;
  z-index: 1000;
}

.help-center {
  color: #e6e6e6;
  padding: 10px 40px 10px 20px;
  position: relative;
  margin: 0 5px;
  border-radius: 5px;
  transition: all 0.3s;
}

.help-center:hover {
  background-color: #fff5;
}

.help-center::after {
  position: absolute;
  content: '';
  width: 0px;
  height: 0px;
  border: 8px solid transparent;
  border-left: 8px solid white;
  top: 0;
  right: 0;
  margin-top: 14px;
  margin-right: 10px;
  font-weight: 700;
}

.log-in {
  background-color: #4aa7e5;
  padding: 10px 30px 10px 30px;
  border-radius: 5px;
  color: #e6e6e6;
  font-weight: 700;
  transition: all 0.3s;
  margin: 0 5px;
}

.log-in:hover {
  background-color: #7abfec;
}

.sign-in {
  background-color: #f8e84d;
  padding: 10px 30px 10px 30px;
  border-radius: 5px;
  color: #148;
  font-weight: 700;
  transition: all 0.3s;
  margin: 0 5px;
}

.sign-in:hover {
  background-color: #faef89;
}

/* user header */

.User__Header-container { 
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
  background-color: var(--FEDERAL_BLUE);
  z-index: 1;
  min-width: 300px;
  position: fixed;
}

.Header__logo {
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin-right: 5px;
}

.Header__logo2 {
  width: 49px;
  height: 50px;
  cursor: pointer;
  margin-right: 6px;
}

.Header__User-menu {
  position: absolute;
  background-color: white;
  width: 260px;
  height: 500px;
  top: 0;
  left: 0;
  margin: 30px;
  padding: 25px;
  box-sizing: border-box;
  padding-top: 70px;
  transition: all 0.5s;
  overflow: hidden;
  box-shadow: 2px 2px 5px 2px #5555;
  z-index: 10;
}

.Header__Menu-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row;
  text-decoration: none;
  color: var(--FEDERAL_BLUE);
  margin: 20px 0;
}

.Header__Menu-item:hover {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.Header__Menu-item:active {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.Header__Menu-item:focus {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.Header__Menu-item img {
  width: 30px;
  margin-right: 10px;
}

.Header__Menu-item p {
  margin: 0;
  font-size: 1.6em;
}

.Header__Menu-btn {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  border: 2px solid #FFF
}

.Header__Menu-btn:active {
  background-color: #729ec7;
}

.Header__Menu-btn img {
  width: 30px;
  height: 30px;
}

.Header__Create-btn {
  width: 180px;
  background-color: var(--FEDERAL_BLUE);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row;
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 7px;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  color: white;
}

.Header__Create-btn:active {
  width: 180px;
  background-color: var(--FEDERAL_BLUE_SOFT);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row;
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 7px;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  color: white;
}

.Header__Create-btn img {
  height: 25px;
  margin-right: 10px;
}

.Header__User-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 0;
  box-sizing: border-box;
  padding: 15px;
  padding-right: 5px;
  height: inherit;
}

.Header__User-Btn {
  background-color: transparent;
  width: 45px;
  border: none;
  border-radius: 5px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  position: relative;
}

.Header__User-Btn:hover {
  background-color: #fff5;
}

.Header__User-buttons img {
  cursor: pointer;
  height: 35px;
}

.Header__Route-title {
  color: white;
  /* text-transform: uppercase; */
  margin: 0 8px;
  word-wrap: none;
  white-space: nowrap;
}

.Header__User-buttons-divider {
  width: 1px;
  height: 100%;
  background-color: white;
  margin: 0 8px;
}

.user-help-center {
  color: #e6e6e6;
  padding-right: 35px;
  height: 45px;
  position: relative;
  margin: 0 5px;
  border-radius: 5px;
  transition: all 0.3s;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
}

.user-help-center:hover {
  background-color: #fff5;
}

.user-help-center::after {
  position: absolute;
  content: '';
  width: 0px;
  height: 0px;
  border: 8px solid transparent;
  border-left: 8px solid white;
  top: 0;
  right: 0;
  margin-top: 15px;
  margin-right: 4px;
  font-weight: 700;
}

.Header__Faq-button {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}
.Header__Faq-button img {
  width: 100%;
  margin: 0;
}

@media (max-width: 840px) {
  .header__menu-img {
    width: 50px;
    position: absolute;
    right: 0;
    margin-right: 15px;
    -webkit-tap-highlight-color: transparent;
  }

  .header__menu-img:active {
    background-color: #fff4;
  }

  .header__buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    top: 0;
    width: 200px;
    height: 335px;
    box-sizing: border-box;
    padding: 0px 10px;
    overflow: hidden;
    background-color: var(--FEDERAL_BLACK);
    margin-top: 70px;
    transition: all 0.3s;
    backdrop-filter: blur(2px);
  }

  .help-center,
  .log-in,
  .sign-in {
    width: 100%;
    padding: 0;
    height: 40px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0 0 0;
    -webkit-tap-highlight-color: transparent;
  }

  .help-center {
    background-color: #0006;
  }
}

@media (max-width: 650px) {

  /* user header */
  .User__Header-container {
    padding: 5px;
    height: 50px;
  }

  .Header__Menu-btn {
    width: 35px;
    height: 35px;
    margin-left: 5px;
  }
  
  .Header__Menu-btn img {
    width: 20px;
    height: 20px;
  }

  .Header__User-Btn {
    background-color: transparent;
    width: 30px;
    border: none;
    border-radius: 5px;
    height: 30px;
    transition: all 0.3s;
    margin: 0 3px;
  }
  
  .Header__User-Btn:hover {
    background-color: #fff5;
  }
  
  .Header__User-buttons img {
    cursor: pointer;
    height: 25px;
  }

  .Header__User-buttons-divider {
    margin: 0 5px;
  }
}

@media (max-width: 500px) {
  .logo {
    width: 35px;
    height: 35px;
  }
  .logo2 {
    width: 34px;
    height: 35px;
  }
  .title {
    margin-top: 40px;
  }
  .Header__Title {
    font-size: 1.4em;
  }
  .flags-select-container {
    top: 0px;
    margin-top: 16px;
    /* margin-left: -10px; */
  }
  .header__menu-img {
    width: 30px;
    margin-right: 20px;
  }
}

@media (max-width: 355px) {

  /* user header */

  .Header__Menu-btn {
    width: 30px;
    height: 30px;
    margin-left: 5px;
  }
  
  .Header__Menu-btn img {
    width: 18px;
    height: 18px;
  }

  .Header__User-Btn {
    background-color: transparent;
    width: 25px;
    border: none;
    border-radius: 5px;
    height: 25px;
    transition: all 0.3s;
  }
  
  .Header__User-Btn:hover {
    background-color: #fff5;
  }
  
  .Header__User-buttons img {
    cursor: pointer;
    height: 20px;
  }

  .Header__User-buttons-divider {
    margin: 0 5px;
  }
}

/*@media (max-width: 1115px) {

  .Header__Container {
    justify-content: space-between;
  }

}

@media (max-width: 775px) {

  .Header__Container {
    justify-content: flex-start;
    flex-direction: column;
  }

}

@media (max-width: 480px) {

  .Header__Container {
    padding: 30px;
  }
  
} */
