.AppVideo__Container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
}

.AppVideo__Media {
  width: 100%;
  height: inherit;
  position: absolute;
  max-width: 100%;
  max-height: 500px;
  object-fit: contain;
  overflow: hidden;
}

.AppVideo__PauseOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AppVideo__PauseOverlay img {
  width: 80px;
  height: 80px;
  cursor: pointer;
}

.AppVideo__Controls {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-flow: row;
  width: 100%;
  background: #0008;
  transition: all 0.3s;
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  padding: 5px 10px;
}

.AppVideo__Play-control,
.AppVideo__Loop,
.AppVideo__Mute,
.AppVideo__FullScreen {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin: 2px;
}

.AppVideo__Play-control img,
.AppVideo__Loop img,
.AppVideo__Mute img,
.AppVideo__FullScreen img {
  width: 100%;
}

/* .Appvideo__ProgressBar {
  -webkit-appearance: none !important;
}

.Appvideo__ProgressBar:hover::-webkit-slider-thumb {
  width: 12px;
  height: 12px;
  border-radius: 6px;
}

.Appvideo__ProgressBar::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  cursor: pointer;
  height: 8px;
  width: 8px;
  background-color: var(--FEDERAL_BLUE);
  border-radius: 4px;
  transition: all 0.3s;
}

.Appvideo__ProgressBar::-moz-range-thumb {
  background: var(--FEDERAL_BLUE);
  border: none;
}

.Appvideo__ProgressBar::-moz-range-progress {
  background: white;
  border: none;
}

.Appvideo__ProgressBar {
  height: 2.2em;
  -webkit-appearance: none;
} */

/*progress support*/

.Appvideo__ProgressBar {
  outline: none;
  appearance: none !important;
  -webkit-appearance: none !important;
  background-color: #0000;
}

.Appvideo__ProgressBar.Appvideo__SliderProgress {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 10px + var(--ratio) * (100% - 10px));
  border-radius: 20px;
  width: calc(100% - 160px);
  border: none;
  -webkit-appearance: none !important;
  /* display: flex; */
}

.Appvideo__ProgressBar:focus {
  outline: none;
}

/*webkit*/
.Appvideo__ProgressBar::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background: var(--FEDERAL_BLUE);
  border: none;
  /* box-shadow: 0 0 2px black; */
  margin-top: calc(max((12px - 1px - 1px) * 0.5,0px) - 18px * 0.5);
}

.Appvideo__ProgressBar::-webkit-slider-runnable-track {
  height: 5px;
  border: none;
  border-radius: calc(5px / 2);
  background: #0000;
  box-shadow: none;
}

.Appvideo__ProgressBar::-webkit-slider-thumb:hover {
  background: var(--FEDERAL_BLUE_SOFT);
}

.Appvideo__ProgressBar:hover::-webkit-slider-runnable-track {
  background: #0000;
}

.Appvideo__ProgressBar::-webkit-slider-thumb:active {
  background: var(--FEDERAL_BLUE_STRONG);
}

.Appvideo__ProgressBar:active::-webkit-slider-runnable-track {
  background: #0000;
  /* border-color: #c1c1c1; */
}

.Appvideo__ProgressBar.Appvideo__SliderProgress::-webkit-slider-runnable-track {
  background: linear-gradient(var(--FEDERAL_BLUE), var(--FEDERAL_BLUE)) 0/var(--sx) 100% no-repeat, #FFF8;
}

.Appvideo__ProgressBar.Appvideo__SliderProgress:hover::-webkit-slider-runnable-track {
  background: linear-gradient(var(--FEDERAL_BLUE_SOFT), var(--FEDERAL_BLUE_SOFT)) 0/var(--sx) 100% no-repeat, #FFFC;
}

.Appvideo__ProgressBar.Appvideo__SliderProgress:active::-webkit-slider-runnable-track {
  background: linear-gradient(var(--FEDERAL_BLUE_STRONG), var(--FEDERAL_BLUE_STRONG)) 0/var(--sx) 100% no-repeat, #FFFC;
}

/*mozilla*/
.Appvideo__ProgressBar::-moz-range-thumb {
  width: 12px;
  height: 12px;
  border-radius: calc(12px / 2);
  background: var(--FEDERAL_BLUE);
  border: none;
  /* box-shadow: 0 0 2px black; */
}

.Appvideo__ProgressBar::-moz-range-track {
  height: 5px;
  border: none;
  border-radius: calc(5px / 2);
  /* background: #efefef; */
  box-shadow: none;
}

.Appvideo__ProgressBar::-moz-range-thumb:hover {
  background: var(--FEDERAL_BLUE_SOFT);
}

.Appvideo__ProgressBar:hover::-moz-range-track {
  background: #0000;
}

.Appvideo__ProgressBar::-moz-range-thumb:active {
  background: var(--FEDERAL_BLUE_STRONG);
}

.Appvideo__ProgressBar:active::-moz-range-track {
  background: #0000;
}

.Appvideo__ProgressBar.Appvideo__SliderProgress::-moz-range-track {
  background: linear-gradient(var(--FEDERAL_BLUE), var(--FEDERAL_BLUE)) 0/var(--sx) 100% no-repeat, #FFF8;
}

.Appvideo__ProgressBar.Appvideo__SliderProgress:hover::-moz-range-track {
  background: linear-gradient(var(--FEDERAL_BLUE_SOFT), var(--FEDERAL_BLUE_SOFT)) 0/var(--sx) 100% no-repeat, #FFFC;
}

.Appvideo__ProgressBar.Appvideo__SliderProgress:active::-moz-range-track {
  background: linear-gradient(var(--FEDERAL_BLUE_STRONG), var(--FEDERAL_BLUE_STRONG)) 0/var(--sx) 100% no-repeat, #FFFC;
}

/*ms*/
.Appvideo__ProgressBar::-ms-fill-upper {
  background: transparent;
  border-color: transparent;
}

.Appvideo__ProgressBar::-ms-fill-lower {
  background: transparent;
  border-color: transparent;
}

.Appvideo__ProgressBar::-ms-thumb {
  width: 2em;
  height: 2em;
  border-radius: 1em;
  background: #007cf8;
  border: none;
  box-shadow: 0 0 2px black;
  margin-top: 0;
  box-sizing: border-box;
}

.Appvideo__ProgressBar::-ms-track {
  height: 1em;
  border-radius: 0.5em;
  background: #efefef;
  border: 1px solid #b2b2b2;
  box-shadow: none;
  box-sizing: border-box;
}

.Appvideo__ProgressBar::-ms-thumb:hover {
  background: #0061C3;
}

.Appvideo__ProgressBar:hover::-ms-track {
  background: #e5e5e5;
  border-color: #9a9a9a;
}

.Appvideo__ProgressBar::-ms-thumb:active {
  background: #2F98F9;
}

.Appvideo__ProgressBar:active::-ms-track {
  background: #f5f5f5;
  border-color: #c1c1c1;
}

.Appvideo__ProgressBar.Appvideo__SliderProgress::-ms-fill-lower {
  height: max(calc(1em - 1px - 1px),0px);
  border-radius: 0.5em 0 0 0.5em;
  margin: -1px 0 -1px -1px;
  background: #007cf8;
  border: 1px solid #b2b2b2;
  border-right-width: 0;
}

.Appvideo__ProgressBar.Appvideo__SliderProgress:hover::-ms-fill-lower {
  background: #0061c3;
  border-color: #9a9a9a;
}

.Appvideo__ProgressBar.Appvideo__SliderProgress:active::-ms-fill-lower {
  background: #2f98f9;
  border-color: #c1c1c1;
}

.AppVideo__Velocity-menu {
  appearance: none;
  background: none;
  color: var(--FEDERAL_BLUE);
  outline: none;
  border: none;
  text-align: start;
  font-size: 1em;
  text-align: center;
}

.mute-btn {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.mute-btn i {
  color: white;
  font-size: 20px;
}

/* @media (display-mode: fullscreen) {
  .AppVideo__Container {
    position: fixed !important;
  }

  .AppVideo__Media {
    position: relative;
    left: 0px;
    top: 0px;
    min-width: 0px;
    max-width: none;
    min-height: 0px;
    max-height: none;
    width: 100%;
    height: 100%;
    display: block;
    transform: none;
    margin: 0px !important;
    flex: 1 1 0% !important;
  }

  .AppVideo__Media::-webkit-media-controls {
    display: none !important;
  }
  .AppVideo__Media::-webkit-media-controls-enclosure {
    display: none !important;
  }
  .AppVideo__Media::-webkit-media-controls-overlay-enclosure {
    display: none !important;
  }

  .AppVideo__Controls {
    position: absolute;
    z-index: 2147483648 !important;
  }

  :-webkit-full-screen {
    z-index: 2147483647 !important;
  }
} */
