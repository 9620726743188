.CarouselContainer {
  background-color: #2773b4;
  width: 100%;
  /* min-height: 400px; */
  box-sizing: border-box;
  padding: 20px;
  padding-top: 40px;
  position: relative;
}

.CarouselContainer h1 {
  color: white;
  font-family: 'Source Sans Pro';
  font-size: 3em;
  position: relative;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 8px;
  width: 100%;
}

/* .CarouselContainer h1::after {
  content: '';
  width: 300px;
  height: 2px;
  background-color: #fff;
  bottom: 0;
  position: absolute;
} */

.CardsCarousel {
  width: 100%;
  min-height: 400px;
  margin: 15px;
  display: flex;
  max-width: 1100px;
}

.ClientContainer {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ClientContainer img {
  width: 250px;
  height: 250px;
  border: 7px solid #fff;
  border-radius: 50%;
  background-color: #fff;
}

.TextCritic {
  text-align: start;
  width: 60%;
  font-family: 'Source Sans Pro';
  box-sizing: border-box;
  padding: 25px
}

.TextCritic h1 {
  text-align: start;
  /* width: 290px; */
  font-size: 2.6em;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
}

.TextCritic h1::after {
  display: none;
}

.TextCritic h4 {
  text-align: start;
  font-weight: lighter;
  color: #5fdba7;
  margin: 0;
  font-size: 1.8em;
  font-weight: 500;
}

.TextCritic h2 {
  text-align: start;
  color: #5fdba7;
  margin: 0;
  margin-left: 10px;
  text-transform: uppercase;
  font-size: 1.8em;
  margin-bottom: 10px;
}

.TextCritic p {
  color: #fff;
  margin: 0;
  font-size: 1.5em;
}

.Home__Carousel-btn {
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  align-self: center;
  margin: 0;
  cursor: pointer;
}

.Home__Carousel-btn:disabled {
  opacity: .5;
  cursor: default;
}

.Home__Carousel-flex {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-flow: row;
}

.Home__Carousel-pagination {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  margin: 3px 10px;
  margin-bottom: 40px;
  border-radius: 14px;
  transition: all .3s;
}

.Home__Carousel-pagination div {
  background-color: white;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  transition: inherit;
}

.Home__Carousel-arrow {
  height: 100%;
}

.CarouselContainer svg {
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: -1px;
}

.diagonal3 {
  fill: #fff;
  stroke: none;
}

@media (max-width: 850px) {

  .TextCritic h1 {
    font-size: 2.2em;
    margin: 0;
    padding: 0;
  }
  
  .TextCritic h4 {
    font-size: 1.5em;
  }
  
  .TextCritic h2 {
    font-size: 1.5em;
  }
  
  .TextCritic p {
    font-size: 1.6em;
  }
}

@media (max-width: 690px) {
  .CarouselContainer h1 {
    font-size: 2em;
  }

  .CardsCarousel {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ClientContainer {
    width: 100%;
  }

  .ClientContainer img {
    height: 200px;
    width: 200px;
  }

  .TextCritic {
    width: 100%;
    justify-content: center;
  }

  .TextCritic h1 {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .TextCritic h4 {
    display: flex;
    justify-content: center;
  }
  
  .TextCritic h2 {
    display: flex;
    justify-content: center;
    margin-left: 0;
  }
  
  .TextCritic p {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .sc-iBPRYJ.bkVSTc.rec.rec-pagination {
    display: var(--mediaDisplay);
  }
}

@media (max-width: 490px) {

  .CarouselContainer {
    padding: 10px;
    padding-top: 40px;
    padding-bottom: 20px;
  }

  .TextCritic {
    padding: 0;
  }

  .ClientContainer {
    /* width: 140px;
    height: 140px; */
    margin-bottom: 20px;
  }

  .TextCritic h1 {
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  
  .TextCritic h4 {
    font-size: 1em;
    text-align: center;
  }
  
  .TextCritic h2 {
    font-size: 1.2em;
    text-align: center;
  }
  
  .TextCritic p {
    font-size: 1.1em;
  }

  .sc-dlfnbm.cukhLH.rec.rec-slider-container {
    margin: 0 -10px;
  }
}