.NotFoundItem__Container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  width: 220px;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  user-select: none;
}

