@import url('../../../utils/color-palatte.css');

.AddCreditCard__Overlay {
  background: #0008;
  box-sizing: border-box;
  padding: 5px;
}

.AddCreditCard__Container {
  background-color: #fff;
  width: 100%;
  max-width: 550px;
  min-height: 300px;
  border-radius: 10px;
  background-image: url(../../../assets/payment-modals/credit_card_bg.svg);
  background-position: 100% 100%;
  background-size: 135px;
  background-repeat: no-repeat;
  overflow: auto;
  max-height: calc(100vh - 20px);
}

.AddCreditCard__Container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.AddCreditCard__Head {
  background-color: var(--FEDERAL_BLUE);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  box-sizing: border-box;
  padding: 10px 20px;
  border-radius: 10px 10px 0 0;
}

.AddCreditCard__CloseBtn {
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  margin: 10px 10px 0 0;
  cursor: pointer;
}

.AddCreditCard__Header {
  position: relative;
  width: 100%;
}

.AddCreditCard__Header h1 {
  width: 100%;
  text-align: center;
  font-size: 1.8rem;
  color: #fff;
  position: relative;
}

.AddCreditCard__Header h1::before {
  content: '';
  position: absolute;
  width: calc(100% - 30px);
  height: 3px;
  background: linear-gradient(
    90deg,
    transparent,
    var(--FEDERAL_WHITE),
    transparent
  );
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  margin-bottom: -10px;
}

.AddCreditCard__Header img {
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  margin: 10px 0 0 0;
  cursor: pointer;
}

.AddCreditCard__Bill {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  color: white;
  border-bottom: 2px solid #fff;
}

.AddCreditCard__Service p {
  /* text-transform: capitalize; */
  font-size: 1.1rem;
  width: 110px;
  margin: 3px;
}
.AddCreditCard__Service p span {
  font-size: 1.2rem;
  font-weight: bold;
}

.AddCreditCard__Total p {
  /* text-transform: capitalize; */
  font-size: 1.2rem;
  text-align: right;
  margin-top: 0;
}
.AddCreditCard__Total p span {
  font-size: 1.4rem;
  font-weight: bold;
}

.AddCreditCard__Support {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: flex-start;
  flex-flow: column nowrap;
}

.AddCreditCard__Support p {
  color: #fff;
  font-size: 1.1rem;
  margin: 8px 0;
}

.AddCreditCard__Img {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
  padding-bottom: 15px;
}

.AddCreditCard__Img img {
  width: 40px;
}
.AddCreditCard__Img img:nth-child(2) {
  margin: 0 10px;
}

.AddCreditCard__Form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  box-sizing: border-box;
  padding-top: 20px;
}

.AddCreditCard__Form form {
  width: calc(100% - 200px);
}

.AddCreditCard__Form button {
  background-color: var(--FEDERAL_BLUE);
  color: #fff;
  min-width: 120px;
  border: none;
  border-radius: 8px;
  padding: 10px;
  margin: 10px 0 25px 0;
  transition: all 0.3s;
  cursor: pointer;
}

.AddCreditCard__Form button:hover {
  background-color: var(--FEDERAL_BLUE_SOFT);
}

/* STRIPE */


.DemoPickerWrapper {
  padding: 0 12px;
  font-family: "Source Code Pro", monospace;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  background: white;
  margin: 24px 0 48px;
  width: 100%;
}

.DemoPicker {
  font-size: 18px;
  border-radius: 3px;
  background-color: white;
  height: 48px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  border: 0;
  width: 100%;
  color: #6772e5;
  outline: none;
  background: transparent;

  -webkit-appearance: none;
}

.DemoWrapper {
  margin: 0 auto;
  max-width: 500px;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.Demo {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 40%;
}

.AddCreditCard__Label {
  color: var(--FEDERAL_BLACK);
  font-weight: 600;
  letter-spacing: 0.025em;
}

/* button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
} */

.AddCreditCard__Input {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.AddCreditCard__Input::placeholder {
  color: #aab7c4;
}

.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}

.credit-card-form {
  width: 300px;
  margin: 10px
}

.text-inform {
 margin: 10px;
 color: var(--FEDERAL_BLACK);
}

.pay-button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 600px) {
  .AddCreditCard__Container {
    background-size: 95px;
    max-height: calc(100vh - 20px);
  }

  .AddCreditCard__Form form {
    width: calc(100% - 50px);
  }

  .AddCreditCard__Header h1 {
    margin-top: 50px;
  }
}
@media (min-width: 600px) {
  .AddCreditCard__Form form {
    width: calc(100% - 100px);
  }
}