@import url('../../utils/color-palatte.css');

.CreateVote__Important {
    width: 100%;
    border-radius: 10px;
    margin-top: 20px;
    background-color: var(--FEDERAL_BLUE);
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    max-width: 500px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .CreateVote__Important-lineTitle {
    /* width: 100%; */
    /* background-color: white; */
    /* background: var(--DARK_OFFICIAL_BLUE); */
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 3px; */
    /* margin: 30px 0; */
  }
  
  .CreateVote__Important-lineTitle h3 {
    box-sizing: border-box;
    padding: 5px;
    margin: 10px;
    background: var(--DARK_OFFICIAL_BLUE);
    /* padding: 5px 20px; */
    border-radius: 8px;
    color: var(--WHITE_MAIN)
    /* font-size: 1.8em; */
  }
  
  .CreateVote__Important-P {
    text-align: center;
    color: var(--WHITE_MAIN);
    margin-top: 0;
  }

  .smallCircle {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: var(--WHITE_MAIN);
    align-self: end;
  }
  .middleCircle {
    width: 15px;
    height: 15px;
    border-radius: 7.5px;
    background-color: var(--WHITE_MAIN);
    align-self: end;
    margin-left: 5px;
    margin-bottom: 5px;
  }
  .largeCircle {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: var(--WHITE_MAIN);
    align-self: end;
    margin-left: 5px;
    margin-bottom: 10px;
  }

  @media (max-width: 460px) {

    .smallCircle {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: var(--WHITE_MAIN);
      align-self: end;
      margin-left: 30px;
    }
    .middleCircle {
      width: 15px;
      height: 15px;
      border-radius: 7.5px;
      background-color: var(--WHITE_MAIN);
      align-self: end;
      margin-left: 2px;
      margin-bottom: 5px;
    }
    .largeCircle {
      width: 20px;
      height: 20px;
      border-radius: 10px;
      background-color: var(--WHITE_MAIN);
      align-self: end;
      margin-left: 2px;
      margin-bottom: 10px;
    }
  
    .CreateVote__Important {
      width: 90%;
    }

  }