.ThumbImage__Container {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGElEQVQYlWNgYGCQwoKxgqGgcJA5h3yFAAs8BRWVSwooAAAAAElFTkSuQmCC); */
  position: relative;
  background-color: #000;
  border-radius: 5px;
  background-size: 15px;
  overflow: hidden;
}

.ThumbImage-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.Thumb__Transparency {
  position: absolute;
  width: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ThumbVideo__Container {
  width: 100px;
  padding-top: 100%;
  max-height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #0003;
  border-radius: 5px;
  background-size: 15px;
  overflow: hidden;
}

.ThumbVideo-video {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

@media (max-width: 645px) {
  
  .ThumbImage__Container {
    width: 70px;
    height: 70px;
  }

  .ThumbVideo__Container {
    width: 70px;
  }

  .Thumb__Transparency {
    width: 40px;
  }

}