@import url('../../color-palatte.css');

.SearchInput__Container {
  width: 90%;
  height: 40px;
  max-width: 500px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: calc(40px / 2);
  padding: 0 10px;
  margin: 10px auto;
}

.SearchInput__Container img {
  width: 28px;
  margin-right: 10px;
}

.SearchInput {
  background: #0000;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1rem;
  color: var(--FEDERAL_BLUE);
  border: none;
}
.SearchInput::placeholder {
  color: var(--FEDERAL_BLUE);
}

.SearchInputDark {
  background: #0000;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1rem;
  color: #FFF;
  border: none;
}
.SearchInputDark::placeholder {
  color: #FFF;
}