@import url("../../utils/color-palatte.css");

.CommunityProfile__Container {
  width: 100%;
  margin: 0 auto;
  max-width: 800px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
}

.CommunityProfile__Banner {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 33%;
  box-sizing: border-box;
}

.CommunityProfile__Picture {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  margin-bottom: -80px;
  /* width: 100%; */
  height: 140px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
}

.CommunityProfile__Content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
}

.CommunityProfile__LeaderPosts {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #fff;
  align-items: flex-start;
}

.CommunityProfile__LP {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  background-color: #0000;
  padding: 8px 10px;
  flex-flow: column nowrap;
  min-width: 100px;
  height: 90px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
}

.CommunityProfile__LP:hover {
  background-color: #8888;
}

.CommunityProfile__Leader {
  border-radius: 0 0 10px 0;
}

.CommunityProfile__Posts {
  border-radius: 0 0 0 10px;
}

.CommunityProfile__Members {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
}

.CommunityProfile__Text {
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.CommunityProfile__Btn {
  width: 160px;
  height: 40px;
  border: none;
  border-radius: 10px;
  font-family: 'Source Sans Pro', sans-serif;
  background-color: var(--FEDERAL_BLUE);
  color: #fff;
  font-size: 1.1rem;
  margin: 20px;
  transition: all .3s;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.CommunityProfile__Btn:hover {
  background-color: var(--FEDERAL_BLUE_SOFT);
}