.App-footer {
  width: 100%;
  /* height: 65px; */
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  align-items: center;
  font-family: 'Source Sans Pro';
  background-color: #04458F;
  color: #E6E6E6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

}

.App-footer .flag-select__options {
  margin-top: -110px;
}

.App-footer .flag-select__btn {
  cursor: pointer;
}

.app-footer-first-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.terms-and-conditions-text {
 color: white;
 margin-right: 10px;
 margin-left: 10px;
}

.terms-and-conditions-text:hover {
 color: #E6E6E6
}

.App-footer p {
  font-size: 1.2em;
  /* width: 100%; */
  text-align: center;
}

@media (max-width: 550px) {
  .App-footer p {
    font-size: 1em;
  }
}