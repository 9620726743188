.voting-history-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: var(--FEDERAL_WHITE);
    align-items: center;
    justify-content: flex-start;
    position: relative;
  }
  .scrollView {
    width: 100%;
    height: 100%;
  }