.Hierarchy__Container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 800px;
  margin: auto;
  box-sizing: border-box;
}

.Hierarchy__Banner {
  width: 100%;
  background-image: url('../../assets/hierarchy/capitol_blue.svg');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center bottom;
  position: relative;
  box-sizing: border-box;
}

.Hierarchy__Ornament-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  position: absolute;
  bottom: 0;
  height: 300px;
  left: 50%;
  transform: translate(-50%, 0);
  margin-bottom: -180px;
  width: 300px;
}

.Hierarchy__Countdown {
  width: fit-content;
  margin: 0;
  font-size: 1.1rem;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  border-radius: calc(35px / 2);
}

.Hierarchy__Ornaments-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  width: 100%;
  box-sizing: border-box;
  padding-top: 200px;
}

.Hierarchy__Ornament-VP {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  height: 260px;
}

.Hierarchy__Ornament-Cz {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  height: 160px;
  margin-top: 20px;
}

.Hierarchy__Info-Container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  box-sizing: border-box;
  padding: 10px;
  margin-top: 50px;
}

.Hierarchy__PositionInfo-Container {
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  margin: 5px 0;
  padding: 10px;
  box-sizing: border-box;
}

.Hierarchy__PositionInfo-Ornament {
  width: 125px;
  height: 99px;
  margin-right: 10px;
}

.Hierarchy__PositionInfo-content {
  display: flex;
  justify-content: flex-start;
  flex-flow: column nowrap;
}

.Hierarchy__PositionInfo-title {
  margin: 0;
  font-size: 1.8rem;
}

.Hierarchy__PositionInfo-text {
  margin: 5px 0;
}

.Hierarchy__PositionInfo-data {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
}

.Hierarchy__PositionInfo-data p {
  margin-right: 10px;
}