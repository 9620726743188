@import url('../../utils/color-palatte.css');

.ChangePassword__Container {
  width: 100%;
  min-height: 100vh;
  background-color: var(--FEDERAL_BLUE);
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/general/general_background.svg');
  background-size: 620px;
  background-repeat: no-repeat;
  background-position: 100% 100%;
}

.ChangePassword__Form-Container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  max-width: 680px;
}

.ChangePassword__Image {
  width: 150px;
  margin-right: 30px;
}

.ChangePassword__Form {
  width: calc(100% - 180px);
  display: flex;
  min-width: 250px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column;
}

.ChangePassword__Title {
  margin: 0;
  margin-bottom: 15px;
  color: white;
}

.ChangePassword__Dialogue {
  font-size: 1.1em;
  color: white;
  margin-bottom: 7px;
}

.ChangePassword__SendButton {
  width: 150px;
  height: 40px;
  border: none;
  background-color: var(--WARNING_YELLOW);
  color: var(--FEDERAL_BLUE);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.3em;
  border-radius: 10px;
  margin-top: 10px;
  cursor: pointer;
  transition: all .3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ChangePassword__SendButton:hover {
  background-color: var(--FEDERAL_LIGHT_BLUE);
  color: var(--FEDERAL_BLUE);
  transform: scale(1.1, 1.1);
}

@media (max-width: 450px) {
  .ChangePassword__Image {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .ChangePassword__Form {
    justify-content: center;
    align-items: center;
  }
}