@import url('../../utils/color-palatte.css');

.SideMenu__Container {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  box-sizing: border-box;
}

.SideMenu__User-data {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}

.SideMenu__User-profile {
  margin-bottom: 10px;
  width: 100%;
}

.SideMenu__UserFollower-data {
  margin: 15px 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: row nowrap;
}

.SideMenu__Menu {
  width: 100%;
}

.SideMenu__button {
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
  margin-bottom: 10px;
  height: 45px;
  position: relative;
  transition: all 0.3s;
}
.SideMenu__button span {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  transition: all 0.3s;
}
.SideMenu__button p {
  color: var(--FEDERAL_BLUE);
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.3em;
  margin: 0;
}
.SideMenu__button img {
  width: 30px;
  margin-right: 8px;
}

.SideMenu__CreatePost {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  color: #FFF;
  background-color: var(--FEDERAL_BLUE);
  padding: 8px 20px;
  box-sizing: border-box;
  border-radius: 10px;
  transition: all .3s;
  border: none;
  font-family: 'Source Sans Pro', sans-serif;
}
.SideMenu__CreatePost:hover {
  background-color: var(--FEDERAL_BLUE_SOFT);
}
.SideMenu__CreatePost img {
  width: 25px;
  margin-right: 8px;
}
.SideMenu__CreatePost p {
  margin: 0;
  font-size: 1.2rem;
}

.SideMenu__CreateCommunity {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  background-color: var(--WARNING_YELLOW);
  padding: 8px 15px;
  border-radius: 8px;
  transition: all .3s;
  margin-top: 15px;
}
.SideMenu__CreateCommunity:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  background-color: var(--FEDERAL_LOW_BLUE);
  padding: 8px 15px;
  border-radius: 8px;
  transition: all .3s;
}
.SideMenu__CreateCommunity img {
  width: 25px;
  margin-right: 8px;
}
.SideMenu__CreateCommunity p {
  margin: 0;
  color: var(--FEDERAL_BLUE);
  font-size: 1.1rem;
}

/* mobile */
.SideMenu__Mobile-Container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
}
.SideMenu__Mobile-Container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.SideMenu__Mobile-User-data {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column nowrap;
  box-sizing: border-box;
  position: relative;
  padding: 20px;
}

.SideMenu__Mobile-Close-btn {
  width: 25px;
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 20px;
  margin-right: 20px;
}

.SideMenu__Mobile-UserFollower-data {
  margin: 5px 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row nowrap;
}