@import url('../../utils/color-palatte.css');

.Communities__Container {
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  box-sizing: border-box;
  padding: 10px;
  padding-bottom: 20px;
  margin: 0 auto;
}

.Communities__Search-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  margin: 10px 0;
}