@import url('../../utils/color-palatte.css');

.Welcome__Container {
  width: 100%;
  background-color: var(--FEDERAL_BLUE);
  background-image: url('../../assets/welcome/background_flag.svg');
  background-position: right bottom;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-top: 80px;
  min-height: calc(100vh - 70px);
}

.Welcome__Content {
  width: 100%;
  max-width: 650px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 20px;
}

.Welcome__title {
  color: white;
  margin: 0px;
  text-transform: uppercase;
  text-align: center;
}

.Welcome__First-P {
  color: white;
  margin-top: 10px;
  font-size: 1.2em;
}

.Welcome__Last-P {
  color: var(--WARNING_YELLOW);
  align-self: flex-start;
  font-size: 1.1em;
  width: 100%;
}

.Welcome__Button {
  padding: 8px 25px;
  background-color: var(--FEDERAL_LOW_BLUE);
  color: var(--FEDERAL_BLUE);
  font-family: 'Source Sans Pro', sans-serif;
  transition: all .3s;
  font-size: 1.2em;
  border-radius: 7px;
}

.Welcome__Button:hover {
  background-color: var(--WARNING_YELLOW);
  transform: scale(1.1, 1.1);
}

@media (max-width: 650px) {
  
  .Welcome__Container {
    height: calc(100vh - 50px);
  }
}