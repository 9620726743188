@import url('../../utils/color-palatte.css');

.CreateCommunity__PictureContainer {
  width: 150px;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
}

.CreateCommunity__Icon {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  margin-top: 30px;
  width: 50px;
  opacity: .7;
  cursor: pointer;
  z-index: 1;
}

.CreateCommunity__Form {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  padding: 30px;
}

.CreateCommunity__InputContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column nowrap;
}

.CreateCommunity__Form button {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 10px;
  background-color: var(--FEDERAL_BLUE);
  color: var(--WHITE_MAIN);
  font-size: 1rem;
  font-family: 'Source Sans Pro', sans-serif;
  transition: all 0.3s;
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CreateCommunity__Form button:hover {
  background-color: var(--FEDERAL_BLUE_SOFT);
}