@import url('../../utils/color-palatte.css');

.AchievementItem__Container {
  width: 100%;
  margin: 10px 0;
  min-height: 100px;
  background-color: var(--FEDERAL_BLUE);
  border-radius: 10px;
  position: relative;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row nowrap;
}

.AchievementItem__New-chiev-header {
  position: absolute;
  width: 100%;
  height: 40px;
  background-color: var(--FEDERAL_GREEN);
  top: 0;
  left: 0;
  color: var(--FEDERAL_BLUE);
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Achievement__Picture-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-right: 10px;
}

.Achievement__Picture-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
}

.Achievement__content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  flex-flow: column nowrap;
}

.AchievementItem__Title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding-bottom: 5px;
  border-bottom: 2px solid #FFF;
}

.AchievementItem__Title img {
  margin-right: 5px;
}

.AchievementItem__Title p {
  margin: 0;
  font-size: 1.2rem;
  color: var(--WHITE_MAIN);
}

.Achievement__Start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-flow: row nowrap;
}

.Achievement__Description {
  color: var(--WHITE_MAIN);
  margin: 0;
  margin-left: 5px;
}

