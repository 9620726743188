@import url('../../../utils/color-palatte.css');

.SubAdvice__Overlay {
  background: #0008;
  box-sizing: border-box;
  padding: 5px;
}

.SubAdvice__Container {
  background-color: var(--FEDERAL_BLACK_LIGHT);
  width: 100%;
  max-width: 750px;
  min-height: 300px;
  border-radius: 10px;
  max-height: calc(100vh - 40px);
  overflow: auto;
  overflow: overlay;
}

.SubAdvice__Container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.SubAdvice__Container::-webkit-scrollbar-thumb {
  width: 10px;
  height: 10px;
  background: #F000;
}

.SubAdvice__Header {
  width: 100%;
  height: 150px;
  background-color: var(--FEDERAL_BLUE_SOFT);
  background-position: 50% 43%;
  background-size: cover;
  background-repeat: no-repeat;
  color: var(--FEDERAL_WHITE);
  display: flex;
  text-align: center;
  border-radius: 10px 10px 0 0;
  position: relative;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
}

.SubAdvice__Header::after {
  width: 100%;
  height: 150px;
  position: absolute;
  content: '';
  background-image: url(../../../assets/payment-modals/cloud_bg.svg);
  background-position: 50% 41%;
  background-size: 130%;
  background-repeat: no-repeat;
  color: var(--FEDERAL_WHITE);
  border-radius: 10px 10px 0 0;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  z-index: 0;
  opacity: 0.5;
}

.SubAdvice__Close-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  margin: 10px 10px 0 0;
  cursor: pointer;
  z-index: 1;
}

.SubAdvice__Header h1 {
  text-shadow: 3px 3px 5px #0008;
  margin: 0;
  font-size: 1.8rem;
  z-index: 1;
}

.SubAdvice__Content {
  color: var(--FEDERAL_WHITE);
  box-sizing: border-box;
  padding: 0 10px;
}

.SubAdvice__Content h3 {
  position: relative;
  width: 100%;
}

.SubAdvice__Content h3::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background: linear-gradient(
    90deg,
    transparent,
    var(--FEDERAL_WHITE),
    transparent
  );
  left: 0;
  bottom: 0;
  margin-bottom: -10px;
}
.SubAdvice__Content button { 
  background: var(--WARNING_YELLOW);
  color: var(--FEDERAL_BLUE);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 20px auto;
  border: none;
  border-radius: 5px;
  padding: 8px 20px;
  text-decoration: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1rem;
  transition: all 0.3s;
  cursor: pointer;
}

.SubAdvice__Content button:hover {
  background: var(--FEDERAL_LOW_BLUE);
}

@media (max-width: 600px) {
  .SubAdvice__Header h1 {
    font-size: 1.5rem;
  }
  .SubAdvice__Li {
    font-size: 0.9rem;
  }
}