@import url('../../../utils/color-palatte.css');

.PaymentMethod__Overlay {
  background: #0008;
  box-sizing: border-box;
  padding: 5px;
}

.PaymentMethod__Container {
  background-color: #fff;
  color: var(--FEDERAL_BLUE);
  width: 100%;
  max-width: 750px;
  min-height: 300px;
  border-radius: 10px;
  background-image: url(../../../assets/payment-modals/Logo_Bg.svg);
  background-size: 95%;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  display: flex;
  flex-flow: column nowrap;
  padding: 10px 15px;
  box-sizing: border-box;
  overflow: auto;
}
.PaymentMethod__Container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.PaymentMethod__Container h1 {
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.PaymentMethod__Container h1::before {
  content: '';
  position: absolute;
  width: calc(100% - 30px);
  height: 3px;
  background: linear-gradient(
    90deg,
    transparent,
    var(--FEDERAL_BLUE),
    transparent
  );
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  margin-bottom: -5px;
}

.PaymentMethod__Close-btn-black {
  color: #000;
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  margin: 10px 10px 0 0;
  cursor: pointer;
}

.PaymentMethod__Header {
  background: transparent;
  width: 100%;
}

.PaymentMethod__btns {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-flow: column nowrap;
  box-sizing: border-box;
}

.PaymentMethod__btn1 {
  width: 60%;
  background: var(--FEDERAL_BLUE);
  background-image: url(../../../assets/payment-modals/Logo_PayPal.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 15px 0 15px 0;
  border: none;
  border-radius: 5px;
  padding: 8px 20px;
  text-decoration: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1rem;
  transition: all 0.3s;
  cursor: pointer;
}

.PaymentMethod__btn2 {
  width: 60%;
  background: var(--FEDERAL_BLUE);
  background-image: url(../../../assets/payment-modals/Logo_CC.svg);
  background-size: 300px;
  background-repeat: no-repeat;
  background-position: 0 calc(100% + 2px);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 0 15px 0;
  border: none;
  border-radius: 5px;
  padding: 8px 40px;
  text-decoration: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1rem;
  transition: all 0.3s;
  cursor: pointer;
}

.PaymentMethod__btn1:hover {
  background-color: var(--FEDERAL_BLUE_SOFT);
}

.PaymentMethod__btn2:hover {
  background-color: var(--FEDERAL_BLUE_SOFT);
}

.PaymentMethod__Text {
  color: #000;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 0.8rem;
  margin: 10px 5px;
}

.PaymentMethod__ImportantText {
  color: var(--FEDERAL_BLUE);
  width: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 0.8rem;
  margin: -10px 5px 10px 5px;
}

@media (max-width: 600px) {
  .PaymentMethod__Container h1 {
    font-size: 1.5rem;
  }
  .PaymentMethod__Container h1::before {
    margin-bottom: -3px;
  }
  .PaymentMethod__Container {
    background-size: 120%;
  }
  .PaymentMethod__btn2 {
    width: calc(100% - 80px);
  }
}
@media (max-width: 400px) {
  .PaymentMethod__btn2 {
    width: calc(100% - 40px);
  }
}