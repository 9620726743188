@import url('../../../utils/color-palatte.css');

.NewPayment__Overlay {
  background-color: #000a;
}

.NewPayment__Container {
  background-color: #fff;
  box-sizing: border-box;
  width: 100%;
  max-width: 700px;
  padding: 10px;
  border-radius: 10px;
  min-height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.NewPayment__img {
  width: 140px;
  margin: 0 10px;
}

.NewPayment__Content {
  width: calc(100% - 150px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  box-sizing: border-box;
  padding-left: 5px;
}

.NewPayment__Content h1 {
  width: 100%;
  color: var(--FEDERAL_BLUE);
  text-align: center;
  word-wrap: unset;
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 10px;
  text-transform: capitalize;
  position: relative;
}

.NewPayment__Content h1::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background: linear-gradient(
    90deg,
    transparent,
    var(--FEDERAL_BLUE),
    transparent
  );
  left: 0;
  bottom: 0;
  margin-bottom: -10px;
}

.NewPayment__Content p {
  width: 100%;
  text-align: center;
  color: var(--FEDERAL_BLACK);
  font-size: 0.9rem;
}

.NewPayment__Content button {
  width: 100%;
  max-width: 350px;
  height: 35px;
  background-color: var(--FEDERAL_BLUE);
  color: white;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  transition: all 0.3s;
  cursor: pointer;
  margin: 5px;
}
.NewPayment__Content button:hover {
  background-color: var(--FEDERAL_BLUE_SOFT);
}

.NewPayment__Logout {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 35px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

@media (max-width: 600px) {

  .NewPayment__Overlay {
    box-sizing: border-box;
    padding: 5px;
  }
 
  .NewPayment__Container {
    background-color: #fff;
    box-sizing: border-box;
    width: 100%;
    max-width: 700px;
    padding: 10px;
    border-radius: 10px;
    min-height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .NewPayment__img {
    width: 100px;
    margin: 0 5px;
  }
  
  .NewPayment__Content {
    width: calc(100% - 110px);
  }

  .NewPayment__Content h1 {
    font-size: 1.2rem;
  }

  .NewPayment__Content p {
    font-size: 0.75rem;
  }

}