.SucessMailBox {
  background: url(../../assets/sucess-mail/BackgroundBlue.svg);
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 700px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: 'Source Sans Pro';
  color: white;
  box-sizing: border-box;
}

.sucessContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 150px;
}

.sucessContainer img{
  width: 200px;
  margin-right: 20px;
  margin-left: 200px;
}

.SucessText {
  width: 60%;
}

.SucessText h1{
  font-size: 5em;
  margin: 0;
  width: 100%;
}

.sucessContainer p{
  font-size: 1.7em;
  margin: 0;
  width: 70%;
}

.sucessContainer a{
  font-size: 2em;
  margin-top: 20px;
  width: 200px;
  background: #f8e84d;
  color: #04458f;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  border-radius: 8px;
  font-weight: bold;
}

.SucessMailBox svg {
  width: 100%;
  height: 120px;
  position: absolute;
  bottom: 0;
}

.diagonal1 {
  fill: #fff;
  stroke: none;
}

@media (max-width:930px) {
  .SucessText h1{
    font-size: 4em;
  }
  
  .sucessContainer p{
    width: 80%;
  }
}

@media (max-width:840px) {
  .SucessMailBox {
    background: url(../../assets/sucess-mail/BlueBG2.svg);
    background-size: cover;
    background-position: center;
  }
  .sucessContainer {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .sucessContainer img{
    width: 200px;
    margin: 0;
    margin-bottom: 30px;
    margin-left: 60px;
  }
  .SucessText{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 90%;
    box-sizing: border-box;
    margin: 0 10px;
  }
  .SucessText h1{
    font-size: 4em;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
  }
  .SucessText p{
    text-align: center;
    margin: 0;
    width: 100%;
  }
}

@media (max-width: 500px) {
  .sucessContainer img{
    width: 140px;
    margin: 0;
    margin-left: 50px;
    margin-bottom: 30px;
  }
  .SucessText h1{
    font-size: 2.5em;
    text-align: center;
    width: 100%;
  }
  .SucessText p{
    text-align: center;
    margin: 0;
    width: 100%;
  }
}