@import url('https://fonts.googleapis.com/css2?family=GFS+Neohellenic&display=swap');

body {
  margin: 0;
  font-family:'Source Sans Pro', 'GFS Neohellenic', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-color: #8b9ead;*/
  background-color: #2573b4;
}

a {
  text-decoration: none
}
/* form starting stylings ------------------------------- */
.group {
  position:relative;
  margin-bottom:45px;
}

input {
  outline: none;
  appearance: none;
}

input:hover {
  appearance: none;
}

textarea {
  outline: none;
  appearance: none;
}

textarea:hover {
  appearance: none;
}

select {
  appearance: none;
}

button {
  outline: none;
}

/*input {
  color: #e7eae1;
  font-size:18px;
  padding:10px 10px 10px 5px;
  display:block;
  width:100%;
  border:none;
  border-bottom:1px solid #e7eae1;
  background-color: transparent;
  max-width: 300px;
}*/

/* width */

::-webkit-scrollbar {
  width: 10px;
  height: 9px;
}

/* Track */

::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 10px;
  background-color: transparent;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background-color: #e7eae1;
  border-radius: 10px;
}

.flag-select__btn::after {
  border-top: 5px solid #eee !important;
}



.flag-select__btn[aria-expanded="true"]:after {
  border-bottom: none !important;
}

@media (max-width: 620px) {

  .flag-select__btn::after {
    display: none !important;
  }
  
  .flag-select__btn[aria-expanded="true"]:after {
    display: none !important;
  }

  .flag-select__btn::before {
    width: 40px !important;
  }
}