.UserRef__Header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding-bottom: 10px;
    width: 300px;
    /* margin: auto; */
    /* margin-top: 10px;
    margin-bottom: 10px; */
  }

  .VotingCard__UserPicture-container {
    margin-right: 10px;
  }

  .VotingCard__Header-data {
    width: calc(100% - 60px);
    position: relative;
    min-height: 60px;
  }

  .VotingCard__UserPosition-text {
    margin: 0;
    font-weight: bold;
    text-align: center;
    font-size: .9rem;
    min-width: 30;
    height: 16;
    width: fit-content;
    padding: 0 8px;
    border-radius: calc(16px / 2);
    display: flex;
    justify-content: center;
    align-items: center;
  }