.container {
  text-align: center;
}

.container canvas {
  outline: none;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.home-form-container {
  margin-top: 0px;
}