@import url('../../utils/color-palatte.css');

.IncomesItem__Container {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  flex-flow: row nowrap;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px;
  margin: 10px 0;
}

.IncomesItem__ImageBorder {
  width: 70px;
  height: 70px;
  box-sizing: border-box;
  border-radius: calc(70px / 2);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.IncomesItem__ImageBorder img {
  height: 100%;
}

.IncomesItem__Content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column nowrap;
}


.IncomesItem__Header {
  width: 100%;
}

.IncomesItem__Deal-btn {
  padding: 5px 25px;
  font-family: 'Source Sans Pro';
  border: none;
  border-radius: 5px;
  cursor: pointer;
  -webkit-tap-highlight-color: #0000;
}

.IncomesItem__Amount {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}