@import url('../../color-palatte.css');

.CheckBox__Container {
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CheckBox__Container input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
}

.CheckBox {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  overflow: hidden;
}

.CheckBox:checked::after {
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  background-color: var(--FEDERAL_BLUE);
  position: absolute;
  content: '';
  border-radius: 3px;
}

.CheckBox__White {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  overflow: hidden;
}

.CheckBox__White:checked::after {
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  background-color: white;
  position: absolute;
  content: '';
  border-radius: 3px;
}