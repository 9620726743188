@import url("../../utils/color-palatte.css");


.VoteItem__Container {
  width: 100%;
  max-width: 800px;
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #E6E6E6;
  border: none;
  border-radius: 10px;
  padding: 0px 16px;
  box-sizing: border-box;
  background-clip: padding-box; 
  color: var(--FEDERAL_BLUE_SOFT);
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
}

.VoteItem__Img {
  width: 40px;
}

.VoteItem__Counter {
  border-radius: 50px;
  background-color: var(--FEDERAL_BLUE_SOFT);
  padding: 5px;
  min-width: 15px;
  height: 15px;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}