@import url('../../utils/color-palatte.css');

.CommentItem__Container {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  min-height: 80px;
  margin: 10px auto;
  border-radius: 10px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.CommentItem__Header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row nowrap;
}

.CommentItem__Content {
  width: calc(100% - 50px);
  margin-left: 10px;
}

.CommentItem__Text {
  font-size: .95em;
  margin: 5px 0;
}

.CommentItem__Like-area {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: 5px;
  margin-top: 5px;
}

.CommentItem__Like-Button {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}