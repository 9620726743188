.Flow-container {
  width: 100%;
  box-sizing: border-box;
  padding: 40px 0;
  font-family: 'Source Sans Pro';
  margin-bottom: -1px;
  display: flex;
  flex-direction: column;
}

.Flow-container h1 {
  margin: 0 auto;
  color: white;
  font-family: 'Source Sans Pro';
  font-size: 3.2em;
  margin-bottom: 40px;
  box-sizing: border-box;
  /* border-bottom: 4px solid #fff; */
  /* width: 150px; */
}

/* .ImgBG {
  max-width: 700px;
}

.ImgBG, .ImgMini {
  width: 100%;
  margin-bottom: 20px;
  margin-top: -10px;
  margin: auto;
}

.ImgMini {
  display: none;
} */

.Flow__SVG {
  max-width: 700px;
  width: 100%;
  margin: auto;
}

@media (max-width:700px) {

  .Flow-container {
    width: 100%;
    background-color: #2573b4;
    box-sizing: border-box;
    padding: 10px;
    font-family: 'Source Sans Pro';
  }

  .Flow-container h1 {
    margin-bottom: 0px;
    /* border-bottom: 4px solid #fff; */
    /* width: 150px; */
  }

  /* .ImgBG {
    display: none;
  }
  
  .ImgMini {
    display: block;
  } */

  .Flow__SVG {
    width: calc(100% + 80px);
    margin-left: -40px;
  }
}

@media (max-width:400px) {
  .Flow-container h1 {
    font-size: 2.4em;
    /* width: 100px; */
  }

}