@import url('../../utils/color-palatte.css');

.PassDoneContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--FEDERAL_BLUE);
  color: #fff;
  width: 100%;
  height: 90vh;
  background-image: url('../../assets/general/general_background.svg');
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 620px;
}

.PassDoneSubContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 200px;
  width: 80%;
}

.DoneImgContainer {
  width: 150px;
  box-sizing: border-box;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.DoneImgContainer img {
  width: 100%;
}

.DonePassTextContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 50%;
}

.DonePassTextContainer h1 {
  font-size: 3em;
  margin: 0;
}

.DonePassTextContainer p {
  font-size: 1.5em;
  margin: 0;
}

.PasswordDone__Button {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--WARNING_YELLOW);
  padding: 8px 25px;
  font-size: 1.3em;
  color: var(--FEDERAL_BLUE_STRONG);
  border-radius: 8px;
  margin-top: 10px;
  cursor: pointer;
  transition: all .3s;
}

.PasswordDone__Button:hover {
  background-color: var(--FEDERAL_LOW_BLUE);
  transform: scale(1.1, 1.1);
}

@media (max-width:600px) {
  .PassDoneSubContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .DoneImgContainer img {
    margin-bottom: 40px;
    margin-left: 30px;

  }
  .DonePassTextContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
  }
  .DonePassTextContainer h1 {
    font-size: 3em;
    margin-bottom: -20px;
  }
  .DonePassTextContainer p {
    font-size: 1.7em;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

@media (max-width:420px) {
  .PassDoneSubContainer {
    width: 100%;
  }
  .DoneImgContainer img {
    margin-bottom: 40px;
    margin-left: 30px;

  }
  .DonePassTextContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .DonePassTextContainer h1 {
    font-size: 3em;
    margin-bottom: -20px;
  }
  .DonePassTextContainer p {
    width: 90%;
  }
}