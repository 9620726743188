@import url('../../../utils/color-palatte.css');

.Home-benefits {
  width: 100%;
  min-height: 700px;
  background: var(--FEDERAL_BLUE);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 30px;
  margin-bottom: -1px;
}

.Card-Benefit {
  width: 85%;
  max-width: 1500px;
  background-color: white;
  background-image: url('../../../assets/home/benefits/bk_ondulation_horizontal.png');
  background-size: 120%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  min-height: 500px;
  border-radius: 10px;
  box-shadow: -5px 5px 5px 0px rgba(0,0,0,0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  box-sizing: border-box;
  padding: 10px;
}

.Benefits-ImgContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.Benefits-ImgContainer img {
  width: 100%;
}

.Benefits-List{
  width: 100%;
  box-sizing: border-box;
  padding-left: 20px;
}

.Benefits-List h1 {
  width: 100%;
  text-align: start;
  font-size: 2em;
  font-family: 'Source Sans Pro';
  color: var(--GRAY_SECOND);
  margin: 3px 0;
  /* border-bottom: 2px solid var(--GRAY_SECOND); */
  margin-bottom: 15px;
}

.itemList {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 0;
}

.itemList img {
  width: 40px;
}

.itemList p {
  font-family: 'Source Sans Pro';
  color: var(--GRAY_SECOND);
  box-sizing: border-box;
  padding: 0 15px;
  margin: 0;
  font-size: 1.055em;
  text-align: start;
}

@media(max-width:900px) {
  .Card-Benefit {
    flex-direction: column;
    background-image: url('../../../assets/home/benefits/bk_ondulation_vertical.png');
    width: 90%;
  }

  .Benefits-ImgContainer {
    width: 70%;
  }

  .Benefits-List h1 {
    margin-bottom: 25px;
  }

  .Benefits-List{
    padding-bottom: 20px;
  }

  .itemList img {
    width: 30px;
  }
  
  .itemList p {
    font-size: .95em;
  }
}

@media(max-width:450px) {
  .Home-benefits {
    padding: 15px;
  }

  .Card-Benefit {
    width: 100%;
  }

  .Benefits-List h1 {
    width: 122px;
    font-size: 1.7em;
  }

  .itemList img {
    width: 30px;
  }
  
  .itemList p {
    font-size: 1em;
  }
}

