@import '../../utils/color-palatte.css';

.ContactUs__Container {
  width: 100%;
  box-sizing: border-box;
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.ContactUs__h1 {
  font-size: 1.7em;
  margin: 0;
}

.ContactUs__First-p {
  font-size: 2.1em;
  font-weight: 100;
  margin: 20px 0;
  text-transform: uppercase;
  text-align: start;
  justify-self: start;
}

.ContactUs__Form {
  width: 100%;
  max-width: 100%;
  padding: 20px 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin: auto;
}

.ContactUs__Form input {
  width: 100%;
}

.ContactUs__Network-flex {
  width: 40%;
  display: flex;
  height: 100px;
  /* justify-content: space-between; */
  align-items: center;
}

@media (max-width: 1000px) {

  .ContactUs__Network-flex {
    width: 60%;
  }

}
@media (max-width: 700px) {

  .ContactUs__Form {
    padding: 20px 0;
    margin: 0;
    width: 100%;
  }

  .ContactUs__Network-flex {
    width: 80%;
  }

}

@media (max-width: 500px) {

  .ContactUs__Container {
    padding: 40px 10px;
  }

  .ContactUs__Network-flex {
    width: 100%;
  }

}