@import url('../../../utils/color-palatte.css');

.CropModal__Overlay {
  background-color: #0004;
  box-sizing: border-box;
  padding: 5px;
}

.CropModal__Container {
  box-sizing: border-box;
  width: 100%;
  max-width: 800px;
  padding: 15px;
  max-height: calc(100vh - 20px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  border-radius: 10px;
  max-height: calc(100vh - 10px);
  overflow: auto;
}

.CropModal__Container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.CropperModal__Cropper {
  position: relative;
  width: 100%;
  height: 380px;
}

.CropperModal__CloseImg {
  position: absolute;
  right: 0;
  top: 0;
  width: 28px;
  margin-top: 5px;
  margin-right: 5px;
  cursor: pointer;
}

.CropModal__Controls {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 400px;
}

.CropModal__Item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
  width: 100%;
}

.CropModal__Item p {
  width: 45px;
  margin-left: 10px;
}

.CropModal__ControlInput {
  -webkit-appearance: none;
  appearance: none;
  min-width: calc(100% - 55px);
  background-color: var(--FEDERAL_BLUE_TRANSPARENCY);
  height: 2px;
}

.CropModal__ControlInput::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: var(--FEDERAL_BLUE);
  width: 15px;
  height: 15px;
  border-radius: calc(15px / 2);
  position: relative;
}

.CropModal__ControlInput-dark {
  -webkit-appearance: none;
  appearance: none;
  min-width: calc(100% - 55px);
  background-color: #FFF5;
  height: 2px;
}

.CropModal__ControlInput-dark::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: #FFF;
  width: 15px;
  height: 15px;
  border-radius: calc(15px / 2);
  position: relative;
}

.CropModal__Button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: none;
  height: 40px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.2rem;
  margin-bottom: 15px;
  border-radius: 8px;
  cursor: pointer;
}

.CropModal__Button:hover {
  animation: CropModalBtn .2s;
}

@keyframes CropModalBtn {
  0%, 100% {
    transform: scale(1,1);
  } 50% {
    transform: scale(1.1,1.1);
  }
}

