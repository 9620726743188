@import url('../../utils/color-palatte.css');

.SignUp {
  min-height: 650px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--SPACE);;
  /* background-image: url(../../assets/sign-up/sign-up.svg); */
  background-image: url('../../assets/log-in/login-bg.png');
  background-position: 100% 100%;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding-bottom: 30px;
}

.SignUp__Container {
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.SignUp__H1 {
  color: var(--WHITE_MAIN);
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.SignUp__Container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column;
  margin-top: 20px;
}

.SignUp__Info-container {
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  padding: 0 5px;
  display: inline;
  margin-bottom: 10px;
}

.SignUp__Warning {
  width: 30px;
  float: left;
  padding: 0 5px 0 0;
}

.SignUp__Info {
  color: var(--WARNING_YELLOW);
  margin: 0;
  box-sizing: border-box;
  display: inline;
}

.SignUp__Terms-Area {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  } .SignUp__Terms-Area p {
      margin-left: 5px;
    } .SignUp__Terms-Area p span {
        color: var(--FEDERAL_BLUE);
        cursor: pointer;
}

.Create__button {
  border: none;
  background: var(--FEDERAL_BLUE);
  width: 150px;
  height: 44px;
  border-radius: 6px;
  color: white;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.2em;
  margin-top: 20px;
  cursor: pointer;
  transition: all .2s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Create__button:hover {
  border: none;
  background: var(--FEDERAL_BLUE_SOFT);
  border-radius: 6px;
}

@media (min-width: 750px) {
  .SignUp__Container {
    margin-top: 100px;
  }
}

@media (max-width: 750px) {
  .SignUp {
    background-image: none;
  }

  .SignUp {
    background-size: 80%;
  }

}

@media (max-width: 500px) {

  .SignUp {
    box-sizing: border-box;
    padding: 0 10px;
  }
  
  .SignUp__Container {
    transform: scale(.85, .85);
  }

}
