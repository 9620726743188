.Container {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  box-sizing: border-box;
  padding: 0 40px;
  padding-bottom: 60px;
  margin: 0 auto;
}

.Content__Container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column nowrap;
  max-width: 1000px;
  margin: 0px auto;
}

.Content {
  box-sizing: border-box;
  padding: 0 20px;
  white-space: pre-line;
  font-size: 1.2rem;
}
