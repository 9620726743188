.ChangePasswordModal__Overlay {
  background-color: #0008;
  box-sizing: border-box;
  padding: 5px;
}

.ChangePasswordModal__Container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
  width: 100%;
  max-width: 800px;
  min-height: 300px;
  max-height: calc(100vh - 10px);
  overflow: auto;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 10px;
}
.ChangePasswordModal__Container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.ChangePasswordModal__CloseBtn {
  position: absolute;
  width: 25px;
  right: 0;
  top: 0;
  margin-top: 15px;
  margin-right: 15px;
}

.ChangePasswordModal__Title {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0;
}

.ChangePasswordModal__Icon {
  width: 120px;
  margin: 20px 0;
}

.ChangePasswordModal__Form {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column nowrap;
  width: calc(100% - 100px);
}

@media (max-width: 500px) {

  .ChangePasswordModal__Title {
    font-size: 1.7rem;
  }

  .ChangePasswordModal__Form {
    width: 100%;
  }

}