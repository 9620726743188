.StatsCards {
  width: 200px;
  color: #04458f;
  margin: 30px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.StatsCards img {
  width: 100px;
  margin-left: 4px;
}

.StatsCards p {
  font-size: 2em;
  margin: 0;
}

.StatsCards h2 {
  font-family: 'Source Sans Pro';
  margin: 0;
  font-size: 2.2em;
}

@media (max-width: 768px) {
  .StatsCards {
    width: 50px;
  }
}
@media (max-width: 500px) {

  .StatsCards img {
    width: 100px;
  }

}