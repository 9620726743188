@import url('../../color-palatte.css');

.DateInput__Container {
  width: 230px;
  padding: 5px 0px;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: column;
}

.DateInput__Container p {
  margin: 0;
  margin-bottom: 10px;
  margin-left: 3px;
}

.DateInput {
  width: 100%;
  max-width: 230px;
  height: 40px;
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 12px;
  color: var(--FEDERAL_BLUE);
  background-color: transparent;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em;
  background-image: url(../../../assets/app-inputs/date-input/icon_date_picker.svg);
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: calc(100% - 5px) center;
}

.DateInput::placeholder {
  color: var(--FEDERAL_BLUE);
}

.DateInput::-webkit-calendar-picker-indicator {
  filter: opacity(0);
}

.DateInput-white {
  width: 100%;
  max-width: 230px;
  height: 40px;
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 12px;
  color: #FFF;
  background-color: transparent;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em;
  background-image: url(../../../assets/app-inputs/date-input/icon_date_picker_white.svg);
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: calc(100% - 5px) center;
}

.DateInput-white::placeholder {
  color: #FFF;
}

.DateInput-white::-webkit-calendar-picker-indicator {
  filter: opacity(0);
}