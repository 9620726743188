.VotingCard__Container {
  width: 100%;
  min-height: 200px;
  margin: 10px 0;
  max-width: 500px;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 15px;
  position: relative;
}

.approvedButtonContainer {
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.approvedTextContainer {
  display: flex;
  background-color: var(--WHITE_MAIN);
  border-radius: 5px;
  padding: 4px;
  margin: 5px ;
  flex-direction: row;
  align-items: center;
}

.approvedText {
  color: var(--FEDERAL_BLUE_STRONG);
  font-size: 10px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
  margin-left: 5px;
}

.approvedButtonText {
  font-size: 15px;
  color: var(--FEDERAL_WHITE);
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: bold;
}

.VotingCard__Header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding-bottom: 10px;
}

.VotingCard__UserPicture-container {
  margin-right: 10px;
}

.VotingCard__Header-data {
  width: calc(100% - 60px);
  position: relative;
  min-height: 60px;
}

.VotingCard__UserPosition-text {
  margin: 0;
  font-weight: bold;
  text-align: center;
  font-size: .9rem;
  min-width: 30;
  height: 16;
  width: fit-content;
  padding: 0 8px;
  border-radius: calc(16px / 2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.VotingCard__VoteType {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
  width: fit-content;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  text-transform: capitalize;
}
.VotingCard__VoteType span {
  text-transform: uppercase;
}

.VotingCard__SeeItem {
  border: none;
  cursor: pointer;
  margin: 10px auto;
  border-radius: 5px;
  padding: 8px 20px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1rem;
}

.VotingCard__Voted-area {
  position: absolute;
  bottom: 0;
  margin-bottom: -10px;
  left: 0;
  margin-left: -15px;
  width: calc(100% + 30px);
  height: 60px;
  border-radius: 0 0 10px 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}