@import url('../../color-palatte.css');

.RightComponentItem__Container {
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  border-radius: 10px;
}

.RightComponentInput {
  border: none;
  color: var(--FEDERAL_BLUE);
  background-color: transparent;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em;
}

.RightComponentInput::placeholder {
  color: var(--FEDERAL_BLUE);
  /* For Firefox Browser */
  opacity: 1;
}

.RightComponentInput-white {
  border: none;
  color: white;
  background-color: transparent;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em;
}

.RightComponentInput-white::placeholder {
  color: white;
  opacity: 1;
}

.RightComponentInput__Image {
  position: absolute;
  right: 0;
  width: 30px;
  margin-right: 10px;
}

.RightComponentTextArea {
  min-height: 100px;
  border: none;
  color: var(--FEDERAL_BLUE);
  background-color: transparent;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em;
  resize: none;
}

.RightComponentTextArea::placeholder {
  color: var(--FEDERAL_BLUE);
  /* For Firefox Browser */
  opacity: 1;
}

.RightComponentTextArea-white {
  min-height: 100px;
  border: none;
  color: white;
  background-color: transparent;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em;
  resize: none;
}

.RightComponentTextArea-white::placeholder {
  color: white;
  opacity: 1;
}

.RightComponentItem__ItemContainer {
  position: absolute;
  width: 50px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  margin-right: 5px;
}