.CommunityItem__Groups {
  min-height: 200px;
  width: 100%;
  background: linear-gradient(
    180deg,
    var(--FEDERAL_BLUE_SOFT),
    var(--FEDERAL_BLUE)
  );
  border-radius: 10px;
  position: relative;
  padding: 15px;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  margin-bottom: 25px;
}

.CommunityItem__Header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column nowrap;
}

.CommunityItem__Content {
  /* width: calc(100% - 185px); */
  /* margin-left: 185px; */
  min-height: 120px;
  overflow: hidden;
}

.CommunityItem__Content h1 {
  width: 100%;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0px;
  font-size: 1.8rem;
  color: #fff;
  margin-left: 10px
}

.CommunityItem__Content p {
  margin-left: 10px;
}

.CommunityItem__ImgGroup {
  height: 100px;
  width: 100px;
  background: white;
  border-radius: 25px;
  box-shadow: 1px 1px 7px #0008;
  /* margin-left: 25px;
  margin-top: -40px; */
  border: 4px solid #fff;
  object-fit: cover;
}

.CommunityItem__footer {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
  position: relative;
}

.CommunityItem__JoinBtn {
  width: 150px;
  height: 30px;
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: var(--WARNING_YELLOW);
  border: none;
  border-radius: 5px;
  color: var(--FEDERAL_BLUE);
  transition: all 0.3s;
  cursor: pointer;
}

.CommunityItem__JoinBtn:hover {
  background-color: var(--FEDERAL_LOW_BLUE);
}

@media (max-width: 600px) {

  .CommunityItem__Content {
    /* width: calc(100% - 130px);  */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    flex-flow: column nowrap;
    /* margin-left: 100px; */
  }

  .CommunityItem__Content h1 {
    font-size: 1.5rem;
  }

  .CommunityItem__Content p {
    max-height: 120px;
    overflow: hidden;
    position: relative;
  }
}
